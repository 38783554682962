import axios from "axios";
import {useState, useEffect, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {AuthContext} from "../../context/AuthContext";

export default function Login() {
    const {setAuthentication} = useContext(AuthContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);

    useEffect(() => {
        const inputSenha = document.getElementById("yourPassword");

        const handleKeyDown = (e: { getModifierState: (arg0: string) => any; which: number; }) => {
            if (!e.getModifierState("CapsLock")) {
                setCapsLockOn(false);
            } else {
                setCapsLockOn(true);
            }

            if (e.which === 20) {
                setCapsLockOn(prevState => !prevState);
            }
        };

        inputSenha?.addEventListener("keydown", handleKeyDown);

        return () => {
            inputSenha?.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const handleFocusOut = () => {
        setCapsLockOn(false);
    };

    const login = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault()
        let regex = /^[\d.-]{3,14}$/;

        let emailCpf = email.replace(/[.\-\s]/g, '') as any;
        if (emailCpf.length === 11 && !isNaN(emailCpf) && regex.test(emailCpf)) {
            emailCpf = emailCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        } else {
            emailCpf = email.toLowerCase().replace(/\s/g, '')
        }

        axios.post(`${process.env.REACT_APP_API_HOST}/api/login`,  {email: emailCpf, password: password.replace(/\s/g, ''), rememberMe:false})
            .then((result) => {
                const res = result.data;
                setAuthentication(res)
                return navigate("/");
            })
            .catch(erro => Swal.fire({
                title: 'Erro!',
                text: 'Login ou senha incorreto',
                icon: 'error',
            }))
    };

    return (
        <>
            <main>
                <div className="container">

                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                    <div className="d-flex justify-content-center py-4">
                                          <span className="logo-lg d-flex align-items-center w-auto">
                                            <img src="/assests/img/apple-touch-icon.png" alt=""/>
                                              {/*<span className="d-none d-lg-block">Clube</span>*/}
                                        </span>
                                    </div>

                                    <div className="card mb-3">

                                        <div className="card-body">

                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Faça login na sua conta</h5>
                                                <p className="text-center small">Digite seu nome de usuário e senha para fazer login</p>
                                            </div>

                                            <form onSubmit={login} className="row g-3 needs-validation" noValidate>

                                                <div className="col-12">
                                                    <label htmlFor="yourUsername"
                                                           className="form-label">Usuário</label>
                                                    <div className="input-group has-validation">
                                                        <span className="input-group-text" id="inputGroupPrepend"><i
                                                            className="bi bi-person-fill"></i></span>
                                                        <input type="text" name="username" className="form-control"
                                                               id="yourUsername" onChange={evento => setEmail(evento.target.value)} required/>
                                                        <div className="invalid-feedback">Por favor insira seu usuário. </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label htmlFor="yourPassword" className="form-label">Senha</label>
                                                    <div className="input-group has-validation">
                                                        <input type={isPasswordVisible ? 'text' : 'password'} onBlur={handleFocusOut} name="password" className="form-control yourPassword"
                                                               id="yourPassword" onChange={evento => setPassword(evento.target.value)} required/>
                                                        <div className="invalid-feedback">Por favor, insira sua senha! </div>

                                                        <span className="input-group-text"  id="inputGroupPrepend" onClick={togglePasswordVisibility}> <i className={`bi fa-fw field-icon toggle-password ${isPasswordVisible ? 'bi-eye-slash-fill' : 'bi-eye-fill'}`}></i> </span>

                                                    </div>

                                                    {capsLockOn &&
                                                        <div className="col">
                                                            <small className="text-danger"><b>Caps lock está ligado</b></small>
                                                        </div>
                                                    }
                                                    <small
                                                        className="form-text text-muted text-right">
                                                        <Link to="/forgotPassoword"> Esqueceu a senha?</Link>
                                                    </small>

                                                </div>

                                                {/*<div className="col-12">*/}
                                                {/*    <div className="form-check">*/}
                                                {/*        <input className="form-check-input" type="checkbox"*/}
                                                {/*               name="remember" value="true" id="rememberMe"/>*/}
                                                {/*            <label className="form-check-label" htmlFor="rememberMe">Remember*/}
                                                {/*                me</label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit">Login
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                    <p className="small mb-0">Não tem conta?
                                                        <Link to={"/register"}> Crie a sua conta aqui</Link>
                                                    </p>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </section>

                </div>
            </main>

        </>
    );
}