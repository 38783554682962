import React, {useEffect, useRef} from 'react'

// @ts-ignore
import html2pdf from 'html2pdf.js'

interface PrintingComponentProps {
    componentToPrint: JSX.Element;
    textButon: string
    childToParent?: any
}
export default function PrintingComponent({ componentToPrint ,textButon, childToParent}: PrintingComponentProps) {
    const folhaRef = useRef<HTMLDivElement>(null);
    let element = document.getElementById('folha')

    async function  handleGeneratePDF() {
        await new Promise<void>((resolve) => {
            const intervalId = setInterval(() => {
                if (folhaRef.current?.offsetHeight) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, 100);
        }).then(()=>{
            const folha = folhaRef.current;

            if (!folha){
                return;
            } else {
                element = document.getElementById('folha')
            }

            if (element){
                element.style.width = '1200px';
            }

            var opt = {
                filename: 'planilha.pdf',
                margin: 10,
                html2canvas:  { dpi: 192, scale: 2, letterRendering: true, width: 1200, windowWidth: 1200},
                jsPDF: {format: 'a4', orientation: 'portrait'},
                pageBreak: { mode: 'avoid-all'}
            }

            html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdfObj: any) {
                window.open(pdfObj.output('bloburl'), '_blank')
            })
        })
    }

    async function printPDF() {
        await handleGeneratePDF()
    }

    useEffect(() => {
        const fetchData = async () => {
            if (textButon === '') {
                await printPDF().then(() => {
                    if (childToParent) {
                        childToParent('imprimiu');
                    }
                })
            }
        };

        fetchData()
    },  [])

    return (
        <>
            {textButon !== "" &&
                <span onClick={printPDF}> &nbsp; {textButon}</span>
            }

            <div ref={folhaRef}>
                <div style={{visibility: 'collapse'}}>
                    <div id='folha' className='folha'>
                        {componentToPrint}
                    </div>
                </div>
            </div>
        </>
    )
}