import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";
import {MainContext} from "../../context/MainContext";
import IEventTypes from "../../interfaces/IEventTypes";
import ISubEventTypes from "../../interfaces/ISubEventTypes";

export default function SubEventTypesForm() {
    const navigate = useNavigate()
    const {setLoading , setPageTitle} = useContext(MainContext)

    let { id} = useParams()
    let { typeId} = useParams()
    const [active, setActive] = useState<boolean>(true);
    const [description, setDescription] = useState<string>('');
    const [eventTypeId, setEventTypeId] = useState('' as any);

    const dispatchSubEventsObject:any = {
        "active": active,
        "description": description,
        "event_type_id": id === undefined ? typeId : eventTypeId,
    }

    useEffect(() => {
        setLoading(true)
        setPageTitle("Cadastro de Sub-Eventos")

        const fetchData = async () => {
            if (id != undefined) {
               await api.get(`/api/subEventTypes/${id}`).then(resposta => {
                    const eventType = resposta.data

                    setActive(eventType?.active)
                    setDescription(eventType?.description)
                    setEventTypeId(eventType?.event_type_id)
                })
            }
        };

        fetchData().then(setLoading(false))
    },  [])

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();
            setLoading(true)

            const method:'post' | 'put' = id === undefined ? 'post' : 'put';
            const url:string = id === undefined ? '/api/subEventTypes' : `/api/subEventTypes/${id}`;

            api[method](url, dispatchSubEventsObject)
                .then((result) => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 3000
                    }).then(() => navigate(`/events/update/${id === undefined ? typeId : eventTypeId}`));
                })
                .catch(erro => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.error,
                        icon: 'error',
                    });
                });
        }catch (err: any) {
            setLoading(false)
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} className="row g-3">
                <div className="col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="active"
                               onChange={evento => setActive(evento.target.checked)} checked={active}/>
                        <label className="form-check-label" htmlFor="active">Ativo</label>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">
                            <div className="col-md-12">
                                <label htmlFor="description" className="form-label">Descrição <i
                                    className="text-danger"> *</i></label>
                                <input type="text" className="form-control" id="description"
                                       placeholder="Descrição do evento"
                                       onChange={evento => setDescription(evento.target.value)} value={description}/>
                            </div>

                            <div>
                                <button type="submit" className="btn btn-success me-2">Salvar</button>
                                <Link to={`/events/update/${id === undefined ? typeId : eventTypeId}`} className="btn btn-danger">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
);
}

