import React from "react";
import RegisterForm from "./form";
import {AddressProvider} from "../../context/Address/AddressContext";
import {PasswordProvider} from "../../context/PasswordContext";
import {AthleteProvider} from "../../context/Athele/AthleteContext";
import {Link} from "react-router-dom";

export default function Register() {
    return (
        <>
            <main>
                <div className="container">

                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-12 d-flex flex-column align-items-center justify-content-center">

                                    <div className="d-flex justify-content-center py-4">
                                        <span className="logo d-flex align-items-center w-auto">
                                            <img src="/assests/img/apple-touch-icon.png" alt=""/>
                                            <span className="d-none d-lg-block">Clube Tapejara</span>
                                        </span>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Crie a sua conta aqui</h5>
                                                <p className="text-center small">Insira seus dados pessoais para criar uma conta</p>
                                            </div>

                                            <AddressProvider>
                                                <PasswordProvider>
                                                    <AthleteProvider>
                                                        <RegisterForm/>
                                                    </AthleteProvider>
                                                </PasswordProvider>
                                            </AddressProvider>

                                                <div className="col-12 mt-2">
                                                    <p className="small mb-0">Já tem uma conta ?
                                                        <Link to={"/login"}> Login</Link>
                                                    </p>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </section>

                </div>
            </main>
        </>
    );
}

