import React, {useContext, useEffect, useState} from "react";
import api from "../../services/api";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import TermRegister from "./term";
import AddressForm from "../Address/form";
import {AddressContext} from "../../context/Address/AddressContext";
import PasswordForm from "../Password/form";
import {AthleteContext} from "../../context/Athele/AthleteContext";
import {PasswordContext} from "../../context/PasswordContext";
import CacForm from "../Athlete/formCac";
import AtForm from "../Athlete/formAthlete";

export default function RegisterForm() {
    const navigate = useNavigate()
    const {dispatchAddressObject, validateAddressRegister} = useContext(AddressContext)

    const {password ,validatePasswordRegister, passwordIdent} = useContext(PasswordContext)

    const { fullName, cpf, dispatchAthleteObject,validateAthleteRegister} = useContext(AthleteContext)

    const [userId] = useState(0)

    const [agree, setAgree] = useState(false);
    const [email, setEmail] = useState('');

    const objReg:any = {
        ...dispatchAthleteObject,
        "user": {
            "id": userId,
            "email": email,
            "username": fullName.split(' ')[0],
            "identifier": cpf,
            "password": password
        },
        "address": dispatchAddressObject
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            if (!agree){
                throw "Para se cadastrar, é necessário concordar com o termo";
            }

            if (!email) {
                throw "E-mail deve ser informado";
            }

            validateAthleteRegister()
            validatePasswordRegister()
            validateAddressRegister()

            if (!passwordIdent()) {
                api.post('/api/users', objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 5000
                    }).then((result) => {
                        // if(!formReduzido){
                        return navigate("/login");
                        // }else{
                        //   resetaForm();
                        // }
                    });
                }).catch(erro => {
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                    })
                });
            } else {
                Swal.fire({
                    title: 'Erro!',
                    text: 'Senhas devem ser iguais!',
                    icon: 'error',
                })
            }
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className="row g-3">

                <div className="col-md-12">
                    <label htmlFor="email" className="form-label">E-mail <i className="text-danger"> *</i></label>
                    <input type="email" className="form-control" id="email" placeholder="Seu e-mail"
                           onChange={evento => setEmail(evento.target.value)} value={email}/>
                </div>

                <PasswordForm/>

                <AtForm/>

                <h3>CAC</h3>

                <CacForm/>

                <h3>Endereço</h3>

                <AddressForm/>

                <div className="modal fade" id="verticalycentered" tabIndex={-1}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Termo de uso</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <TermRegister/>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Sair
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-check">
                        <input className="form-check-input" name="term" type="checkbox"
                               value="" id="acceptTerms" onChange={evento => setAgree(evento.target.checked)} checked={agree} />

                        <label className="form-check-label" htmlFor="acceptTerms">
                            Concordo e aceito os <a href="javascript:void(0)" data-bs-toggle="modal"
                                                    data-bs-target="#verticalycentered">termos e condições</a></label>
                        <div className="invalid-feedback">Você deve concordar antes de enviar.
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">Criar conta
                    </button>
                </div>

            </form>
        </>
    );
}

