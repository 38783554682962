import React, {useContext, useEffect, useState} from 'react'
import api from "../../services/api";
import {MainContext} from "../../context/MainContext";

export default function HabitualitytPrinting({ athleteId, arrHabitualities}:any) {
    const [loading, setLoading] = useState<any>(true)
    const [athlete, setAthlete] = useState<any>([])
    const [habitualities, setHabituality] = useState<any>([])

    const {club} = useContext(MainContext)

    async function getAthlete() {
        await api.get(`/api/athletes/${athleteId}`).then((response) => {
            const data = response.data
            setAthlete(data)
        })
    }

    async function getHabituality() {
        let arrayHabitualities:any = []

        arrayHabitualities.push({type: "allowed" , habitualities: arrHabitualities.filter((habituality: { weapon: { restricted: number } }) =>
                habituality?.weapon?.restricted == 0
            ).sort((a:any, b:any) => a.weapon?.caliber_id - b.weapon?.caliber_id)});

        arrayHabitualities.push({type: "restricted" , habitualities: arrHabitualities.filter((habituality: { weapon: { restricted: number } }) =>
                habituality?.weapon?.restricted == 1
            ).sort((a:any, b:any) => a.weapon?.caliber_id - b.weapon?.caliber_id)});

        setHabituality(arrayHabitualities)
    }

    useEffect(() => {
        const fetchData = async () => {
            await getAthlete()
            await getHabituality()
        };

        fetchData().then(() => {
            setLoading(false)
        });

    }, [club])

    return (
        <>
            {!loading &&
            <div>
                <div className='fontReport printTable'>
                    <p style={{textAlign: 'center'}}>&nbsp;<strong
                        style={{fontSize: 'large'}}>ANEXO E</strong></p>
                    <p style={{textAlign: 'center'}}><span><strong>COMPROVAÇÃO DE PARTICIPAÇÕES EM TREINAMENTOS E/OU COMPETIÇÕES DE TIRO (HABITUALIDADE)</strong></span>
                    </p>
                    <p style={{textAlign: 'center'}}><span>(art. 35 do Decreto nº 11.615/2023)</span>
                    </p>
                    <p style={{textAlign: 'center'}}>&nbsp;</p>
                    <p><span><strong>Dados da Entidade de Tiro</strong></span></p>
                    <table style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%'
                    }}>
                        <tbody>
                        <tr>
                            <td> NOME
                            </td>
                            <td style={{textAlign: 'center'}}>
                                {club?.fantasia}
                            </td>
                            <td>
                                CNPJ: {club?.cnpj}
                            </td>
                        </tr>
                        <tr>
                            <td> CERTIFICADO DE REGISTRO
                            </td>
                            <td style={{textAlign: 'center'}}>
                                {club?.cr_number}
                            </td>
                            <td>
                                DATA VAL: {club?.cr_validity_date ? new Date(club?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10) : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ENDEREÇO
                            </td>
                            <td>
                                {club?.address?.street}, {club?.address?.number}, {club?.address?.district}
                            </td>
                            <td>
                                {club?.address?.city?.name} - {club?.address?.city?.state?.abbreviation}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p style={{textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{textAlign: 'justify'}}><span
                    ><strong>Dados do Atirador Desportivo</strong></span></p>
                    <table style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%'
                    }}>
                        <tbody>
                        <tr>
                            <td>
                                NOME
                            </td>
                            <td style={{textAlign: 'center'}}>
                                {athlete?.full_name}
                            </td>
                            <td>
                                CPF: {athlete?.cpf}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                CERTIF. DE REGISTRO
                            </td>
                            <td style={{textAlign: 'center'}}>
                                {athlete?.cr_number}
                            </td>
                            <td>
                                DATA VAL: {athlete?.cr_validity_date ? new Date(athlete?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10) : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ENDEREÇO
                            </td>
                            <td>
                                {athlete?.address?.street}, {athlete?.address?.number}, {athlete?.address?.district}
                            </td>
                            <td>
                                {athlete?.address?.city?.name} - {athlete?.address?.city?.state?.abbreviation}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                DADOS DA FILIAÇÃO
                            </td>
                            <td>
                                NÚMERO: {athlete?.member_number}
                            </td>
                            <td>
                                DATA: {athlete?.membership_date ? new Date(athlete?.membership_date).toLocaleString('pt-BR').substr(0, 10) : ''}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p style={{textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{textAlign: 'justify'}}><span
                    ><strong>Dados da Habitualidade</strong></span></p>

                    <>
                        {habitualities.map((habitualities:any, index:number) =>
                            <>
                                <table style={{ marginLeft: 'auto', marginRight: 'auto',  width: '100%' }}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={5}>
                                            Calibre de uso {habitualities?.type == "allowed" ? "permitido" : "restrito" }
                                        </td>
                                        <td>
                                            Tipo de Evento
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}} width="65">
                                            Ordem
                                        </td>
                                        <td style={{textAlign: 'center'}} width="190">
                                            Data-hora
                                        </td>
                                        <td style={{textAlign: 'center'}} width="130">
                                            Sigma
                                        </td>
                                        <td style={{textAlign: 'center'}} width="180">
                                            Calibre
                                        </td>
                                        <td style={{textAlign: 'center'}} width="115">
                                            Qtd Munição
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            Treinamento ou Competição
                                        </td>
                                    </tr>

                                    <>
                                        {habitualities?.habitualities.map((habituality: any, index: number) =>
                                            <tr key={`${index}`}>
                                                <td style={{textAlign: 'center'}}>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {new Date(habituality?.release_date).toLocaleString('pt-BR')}
                                                </td>
                                                <td style={{textAlign: 'justify'}}>
                                                    {habituality?.weapon?.sigma_number}
                                                </td>
                                                <td>
                                                    {habituality?.weapon?.caliber?.description}
                                                </td>
                                                <td style={{textAlign: 'center'}}>
                                                    {habituality?.quantity_shots}
                                                </td>
                                                <td>
                                                    {habituality?.event_type?.description}
                                                </td>
                                            </tr>
                                        )}
                                    </>

                                    <tr>
                                        <td colSpan={6}>
                                            Registros da Habitualidade
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Livro/Sistema
                                        </td>
                                        <td colSpan={3}>
                                            Folha/Nr Registro
                                        </td>
                                        <td>
                                            Data do Lançamento:
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                            </>
                        )}
                    </>


                    <p style={{textAlign: 'center'}}>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        <span>__________________________</span><br/>
                        <span>{club?.president_name}</span><br/>
                        <span>CPF: {club?.president_cpf}</span><br/>
                        <span>Presidente do Clube de Tiro Caça e Pesca Tapejara – RS</span>
                        &nbsp;<br/>
                    </p>

                    <p style={{textAlign: 'center'}}>
                        <br/>
                        <span>____________________________</span><br/>
                        <span>De acordo: {athlete?.full_name}</span><br/>
                        <span>CPF: {athlete?.cpf}</span>
                    </p>

                </div>
            </div>
            }

        </>
    )
}



