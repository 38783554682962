import {useContext} from "react";
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom'
import Content from "./components/template/content";
import Home from "./pages/home";
import {AuthContext} from './context/AuthContext'
import Login from "./pages/login/login";
import Register from "./pages/Register/index";
import NotFound from "./pages/pages/NotFound";
import ListingClub from "./pages/Club/listing";
import ClubSubmissionForm from "./pages/Club/SubmissionForm";
import ListingUsers from "./pages/User/listing";
import UserForm from "./pages/User/form";
import ListingAthletes from "./pages/Athlete/listing";
import AthleteSubmissionForm from "./pages/Athlete/SubmissionForm";
import ListingWeapons from "./pages/Weapon/listing";
import FormWeapons from "./pages/Weapon/form";
import ListingEventType from "./pages/EventTypes/listing";
import EventTypesForm from "./pages/EventTypes/form";
import FormHabituality from "./pages/Habituality/form";
import HabitualityReport from "./pages/Habituality/report";
import Forgot from "./pages/Password/Forgot/forgot";
import Reset from "./pages/Password/Reset/reset";
import ChangePassword from "./pages/Password/Change/change";
import AthetesReport from "./pages/Athlete/report";
import SubEventTypesForm from "./pages/SubEventTypes/form";
import ListingSubEventType from "./pages/SubEventTypes/listing";

const PrivateRoutes = ({role= ''}) => {
    const { authenticated, authUser} = useContext(AuthContext)

    if(!authenticated) return <Navigate to='/login' replace />

    if(authenticated && !!role && role !== authUser.role) return <Navigate to='/' replace />

    return <Outlet />
}

export default function AppRouter() {

    return (
    <div className="App">

            <Router>
                <Routes>

                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/forgotPassoword' element={<Forgot />} />
                    <Route path='/resetPassword/:token' element={<Reset />} />

                    <Route path='' element={ <Content />}>
                        <Route path='*' element={<NotFound />} />

                        <Route path='' element={<PrivateRoutes />}>
                            <Route path='/' element={<Home />} />

                            <Route path='/profile' element={<AthleteSubmissionForm />} />
                            <Route path='/changePassoword' element={<ChangePassword />} />

                            <Route path='members'>
                                <Route path='' element={<ListingAthletes />} />
                                <Route path='report' element={<AthetesReport />} />

                                <Route path='create' element={<AthleteSubmissionForm/>} />
                                <Route path='update/:id' element={<AthleteSubmissionForm/>} />
                            </Route>

                            <Route path='habitualities'>
                                <Route path='create' element={<FormHabituality />} />
                                <Route path='report' element={<HabitualityReport />} />
                            </Route>

                            <Route path='weapons'>
                                <Route path='' element={<ListingWeapons />} />
                                <Route path='create' element={<FormWeapons />} />
                                <Route path='update/:id' element={<FormWeapons />} />
                            </Route>

                            <Route path='events'>
                                <Route path='' element={<ListingEventType />} />
                                <Route path='create' element={<EventTypesForm />} />
                                <Route path='update/:id' element={<EventTypesForm />} />
                                <Route path='sub'>
                                    <Route path='' element={<ListingSubEventType />} />
                                    <Route path='create/:typeId' element={<SubEventTypesForm />} />
                                    <Route path='update/:id' element={<SubEventTypesForm />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route path='admin' element={<PrivateRoutes role={'admin'}/>}>
                            <Route path='clubs'>
                                <Route path='' element={<ListingClub />} />
                                <Route path='create' element={<ClubSubmissionForm />} />
                                <Route path='update/:id' element={<ClubSubmissionForm />} />
                            </Route>

                            <Route path='users'>
                                <Route path='' element={<ListingUsers />} />
                                <Route path='create' element={<UserForm/>} />
                                <Route path='update/:id' element={<UserForm/>} />
                            </Route>
                        </Route>

                    </Route>

                </Routes>
            </Router>
        </div>
    )
}
