import React, {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import api from "../../services/api";
import {dateMountString} from "../../assets/js/helper";

export default function DeclarationAffiliation({athleteId}: any) {
    const [loading, setLoading] = useState<any>(true)
    const [athlete, setAthlete] = useState<any>([])

    const {club} = useContext(MainContext)

    async function getAthlete() {
        await api.get(`/api/athletes/${athleteId}`).then((response) => {
            setAthlete(response.data)
        }).then(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await getAthlete()
        };

        fetchData()
    }, [club])

    return (
        <>
            {!loading &&
                <div className={'printTable'}>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}><strong>ANEXO B</strong></span>
                    </p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}><strong>DECLARAÇÃO DE FILIAÇÃO À ENTIDADE DE TIRO</strong></span>
                    </p>
                    <p style={{textAlign: 'center'}}><span>(Inciso XVII do art. 2º do Decreto nº 11.615/2023)</span></p>

                    <p>
                        <span style={{fontSize: 'large'}}><strong>Dados do Clube de Tiro:</strong></span>
                    </p>

                    <table style={{marginLeft: 'auto', marginRight: 'auto', width: '99%'}}>
                        <tbody>
                            <tr>
                                <td>NOME</td>
                                <td style={{textAlign: 'center'}}>
                                    {club?.fantasia}
                                </td>
                                <td>
                                    CNPJ: {club?.cnpj}
                                </td>
                            </tr>
                            <tr>
                                <td width="270">
                                    CERTIFICADO DE REGISTRO
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    {club?.cr_number}
                                </td>
                                <td width="400">
                                    DATA VAL: {new Date(club?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10)}
                                </td>
                            </tr>
                            <tr>
                                <td>ENDEREÇO</td>
                                <td>  {club?.address?.street}, {club?.address?.number}, {club?.address?.district} </td>
                                <td> {club?.address?.city?.name} - {club?.address?.city?.state?.abbreviation} </td>
                            </tr>

                        </tbody>
                    </table>

                    <p>
                        &nbsp;<br/>
                        <span style={{fontSize: 'large'}}><strong>Dados do Requerente:</strong> </span>
                    </p>

                    <table style={{marginLeft: 'auto', marginRight: 'auto', width: '99%'}}>
                        <tbody>
                        <tr>
                            <td width="270"> DADOS DA FILIAÇÃO</td>
                            <td> NÚMERO: {athlete?.member_number} </td>
                            <td width="400"> DATA: {athlete?.membership_date ? new Date(athlete?.membership_date).toLocaleString('pt-BR').substr(0, 10) : ''} </td>
                        </tr>
                        <tr>
                            <td>NOME</td>
                            <td style={{textAlign: 'center'}}>
                                {athlete?.full_name}
                            </td>
                            <td> CPF: {athlete?.cpf}</td>
                        </tr>
                        <tr>
                            <td> CERTIFICADO DE REGISTRO</td>
                            <td style={{textAlign: 'center'}}>
                                {athlete?.cr_number}
                            </td>
                            <td> DATA VAL: {new Date(athlete?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10)} </td>
                        </tr>
                        <tr>
                            <td>ENDEREÇO</td>
                            <td>{athlete?.address?.street}, {athlete?.address?.number}, {athlete?.address?.district}</td>
                            <td>{athlete?.address?.city.name} - {athlete?.address?.city?.state?.abbreviation}</td>
                        </tr>
                        </tbody>
                    </table>

                    <p style={{textAlign: 'center'}}>
                        <br/>
                        <br/>
                        <br/>
                        <span style={{fontSize: 'large'}}><strong>Declaração</strong></span>
                    </p>
                    <p style={{textAlign: 'justify'}}><span style={{fontSize: 'large'}}>O CLUBE DE TIRO CAÇA E PESCA TAPEJARA, DECLARA, para fim de comprovação de filiação, nos termos do contido no inciso XVII do art. 2º do Decreto nº 11.615, de 21 de julho de 2023, e sob as penas da lei, que o cidadão </span>
                        <span style={{fontSize: 'large'}}><strong>{athlete?.full_name}</strong></span>
                        <span style={{fontSize: 'large'}}>, acima identificado, pertence aos quadros desta Entidade, conforme os dados de filiação acima descritos. </span>
                    </p>
                    <p style={{textAlign: 'justify'}}>
                        <span style={{fontSize: 'large'}}>TAPEJARA, {dateMountString(new Date())}.</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>_________________________</span></p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>{club?.president_name}</span></p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>CPF: {club?.president_cpf}</span></p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>Presidente do Clube de Tiro Caça e Pesca Tapejara – RS</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>____________________________</span></p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>De acordo: {athlete?.full_name}</span></p>
                    <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>CPF:{athlete?.cpf}</span></p>
                </div>
            }
        </>
    )
}