import React, {useContext, useEffect} from "react";
import ChangePasswordForm from "./changePasswordForm";
import {PasswordProvider} from "../../../context/PasswordContext";
import {MainContext} from "../../../context/MainContext";

export default function ChangePassword() {
    const {setPageTitle} = useContext(MainContext)

    useEffect(() => {
        setPageTitle("Alterar senha")
    }, []);

    return (
        <>
            <PasswordProvider>
                <ChangePasswordForm/>
            </PasswordProvider>
        </>
    );
}