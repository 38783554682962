import React, {useContext} from "react";
import PasswordForm from "../form";
import api from "../../../services/api";
import Swal from "sweetalert2";
import {PasswordContext} from "../../../context/PasswordContext";
import {Link} from "react-router-dom";

export default function ChangePasswordForm() {
    const {password, currentPassword ,validatePasswordRegister, passwordIdent} = useContext(PasswordContext)

    const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault()

        validatePasswordRegister(true)

        if (passwordIdent) {
            api.post('/api/change-password', {
                currentPassword: currentPassword,
                password: password
            }).then((result) => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: result.data.message,
                    icon: 'success',
                    timer: 5000,
                }).then((result) => {
                    console.log('trocou')
                    // return navigate("/login");
                })
            }).catch(erro => {
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                    })
                },
            )
        } else {
            Swal.fire({
                title: 'Erro!',
                text: 'Senhas devem ser iguais!',
                icon: 'error',
            })
        }
    }

    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={forgot} autoComplete='off' className="row g-3 mt-2">
                            <PasswordForm changePassword={true} customLabel={{ password: 'Nova Senha', passwordConfirmation: "Confirme a nova senha" }}/>

                            <div className="">
                                <button type="submit" className="btn btn-success me-2">Salvar</button>
                                <Link to={"/"} className="btn btn-danger">Voltar</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
}