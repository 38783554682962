import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";
import AddressForm from "../Address/form";
import InputMask from "react-input-mask";
import {AddressContext} from "../../context/Address/AddressContext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {validaCPF} from "../../assets/js/helper";
import {MainContext} from "../../context/MainContext";

export default function ClubForm() {
    const navigate = useNavigate()
    const {setPageTitle, setLoading} = useContext(MainContext)

    let { id} = useParams()

    const [active, setActive] = useState(true);
    const [presidentName, setPresidentName] = useState('');
    const [presidentCpf, setPresidentCpf] = useState('');

    const [cnpj, setCnpj] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [fantasia, setFantasia] = useState('');
    const [phone, setPhone] = useState('');

    const [crNumber, setCrNumber] = useState('');
    const [crExpedition, setCrExpedition] = useState(null as any);
    const [crValidity, setCrValidity] = useState(null as any);

    const {setAddress,
        setZipCode,
        setStreet,
        setNumber,
        setDistrict,
        setComplement,
        setCity,
        dispatchAddressObject,
        validateAddressRegister} = useContext(AddressContext)

    const objReg:any = {
        "active": active,
        "razao_social": razaoSocial,
        "fantasia": fantasia,
        "cnpj": cnpj,
        "phone": phone,
        "president_name": presidentName,
        "president_cpf": presidentCpf,
        "cr_number": crNumber,
        "cr_expedition_date": crExpedition != null ? dayjs(crExpedition).format('YYYY-MM-DD') : null ,
        "cr_validity_date": crValidity != null ? dayjs(crValidity).format('YYYY-MM-DD') : null ,
        "address": dispatchAddressObject
    }

    useEffect(() => {
        setPageTitle("Cadastro de Clubes")
        setLoading(true)

        const fetchData = async () => {
            if (id != undefined) {
                await api.get('/api/clubs/' + id)
                    .then(resposta => {
                        const club = resposta.data

                        setActive(club?.active)
                        setPresidentName(club?.president_name)
                        setPresidentCpf(club?.president_cpf)
                        setCnpj(club?.cnpj)
                        setRazaoSocial(club?.razao_social)
                        setFantasia(club?.fantasia)
                        setPhone(club?.phone)
                        setCrNumber(club?.cr_number)
                        setCrExpedition(club?.cr_expedition_date != null ? dayjs(club?.cr_expedition_date) : null)
                        setCrValidity(club?.cr_validity_date != null ? dayjs(club?.cr_validity_date) : null)
                        setAddress(club?.address.id)
                        setZipCode(club?.address.zip_code)
                        setStreet(club?.address.street)
                        setNumber(club?.address.number)
                        setDistrict(club?.address.district)
                        setComplement(club?.address.complement)
                        setCity({
                            value: club?.address.city_id,
                            label: club?.address?.city?.name + ' - ' + club?.address?.city?.state.abbreviation,
                        })
                    })
            }
        };

        fetchData().then(setLoading(false))
    },  [])

    const validateClubRegister = () => {
        if (!razaoSocial) {
            throw "Razão social deve ser informada";
        }

        if (!fantasia) {
            throw "Nome fantasia deve ser informado";
        }

        if (!cnpj) {
            throw "CNPJ deve ser informado";
        }

        if (!presidentName) {
            throw "Nome do presidente deve ser informado";
        }

        if (!presidentCpf) {
            throw "CPF do presidente deve ser informado";
        }

        if(!validaCPF(presidentCpf)){
            throw "CPF inválido";
        }

        if (!phone) {
            throw "Celular deve ser informado";
        }

        if (!crNumber) {
            throw "Numero do CR deve ser informado";
        }

        if (!crExpedition) {
            throw "Data de expedição do CR deve ser informada";
        }

        if (!crValidity) {
            throw "Data de validade do CR deve ser informada";
        }
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            validateClubRegister()
            validateAddressRegister()

            if (id == undefined) {
                api.post('/api/clubs', objReg).then((result) => {
                  Swal.fire({
                    title: 'Sucesso!',
                    text: result.data.message,
                    icon: 'success',
                    timer: 5000
                  }).then((result) => {
                      return navigate("/admin/clubs");
                  });
                })
                  .catch(erro => {
                      setLoading(false)
                      Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                      })
                    }
                  );

            } else {
                api.put('/api/clubs/' + id, objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        return navigate("/admin/clubs");
                    });
                }).catch(erro  => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                    })
                })
            }
        }catch (err: any) {
            setLoading(false)
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className="row g-3">
                <div className="col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="active"
                               onChange={evento => setActive(evento.target.checked)} checked={active}/>
                        <label className="form-check-label" htmlFor="active">Ativo</label>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        {/*<h5 className="card-title">Sócio</h5>*/}
                        {/*<AddressForm/>*/}
                        <div className="row g-3 mt-2">
                            <div className="col-md-6">
                                <label htmlFor="president_name" className="form-label">Nome do presidente <i
                                    className="text-danger"> *</i></label>
                                <input type="text" className="form-control" id="president_name"
                                       placeholder="Razão social do clube"
                                       onChange={evento => setPresidentName(evento.target.value)} value={presidentName}/>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="cpf" className="form-label">Cpf do presidente <i
                                    className="text-danger"> *</i></label>
                                <InputMask type="text" className="form-control" id="cpf" placeholder="Seu CPF"
                                           mask="999.999.999-99" onChange={evento => setPresidentCpf(evento.target.value)}
                                           value={presidentCpf}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        {/*<h5 className="card-title">Sócio</h5>*/}
                        {/*<AddressForm/>*/}
                        <div className="row g-3 mt-2">

                            <div className="col-md-6">
                                <label htmlFor="razaoSocial" className="form-label">Razão Social <i
                                    className="text-danger"> *</i></label>
                                <input type="text" className="form-control" id="razaoSocial"
                                       placeholder="Razão social do clube"
                                       onChange={evento => setRazaoSocial(evento.target.value)} value={razaoSocial}/>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="fantasia" className="form-label">Nome Fantasia <i className="text-danger"> *</i></label>
                                <input type="text" className="form-control" id="fantasia"
                                       placeholder="Nome fantasia do clube"
                                       onChange={evento => setFantasia(evento.target.value)} value={fantasia}/>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="cnpj" className="form-label">Cnpj <i className="text-danger"> *</i></label>
                                <InputMask type="text" className="form-control" id="cnpj" placeholder="Cnpj do clube"
                                           mask="99.999.999/9999-99" onChange={evento => setCnpj(evento.target.value)}
                                           value={cnpj}/>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="phone" className="form-label">Celular <i
                                    className="text-danger"> *</i></label>
                                <InputMask type="text" className="form-control" id="phone"
                                           placeholder="Seu número de celular"
                                           mask="(99) 99999-9999" onChange={evento => setPhone(evento.target.value)}
                                           value={phone}/>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        {/*<h5 className="card-title">Sócio</h5>*/}
                        {/*<AddressForm/>*/}
                        <div className="row g-3 mt-2">

                            <div className="col-md-4">
                                <label htmlFor="cr_number" className="form-label">Nº CR <i style={{fontSize:"small"}}>(Incluindo zeros no início)</i> <i className="text-danger"> *</i></label>
                                <input type="text" className="form-control" id="cr_number" placeholder="CR do Clube" onChange={evento => setCrNumber(evento.target.value)} value={crNumber}/>
                            </div>

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="col-md-4">
                                    <label htmlFor="cr_expedition" className="form-label">Data expedição do CR <i
                                        className="text-danger"> *</i></label>
                                        <DatePicker  className={"form-control sadate"}
                                                     value={crExpedition}
                                                     onChange={evento => setCrExpedition(evento)}/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="cr_validity" className="form-label">Data validade do CR <i
                                        className="text-danger"> *</i></label>
                                    <DatePicker  className={"form-control sadate"}
                                                 value={crValidity}
                                                 onChange={evento => setCrValidity(evento)}/>
                                </div>
                            </LocalizationProvider>

                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Endereço</h5>

                        <div className="row g-3">
                        <AddressForm/>
                        </div>
                    </div>
                </div>

                <div className="">
                    <button type="submit" className="btn btn-success me-2">Salvar</button>
                    <Link to={"/admin/clubs"} className="btn btn-danger">Voltar</Link>
                </div>
            </form>
        </>
    );
}

