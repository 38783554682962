import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Select from "react-select";
import {MainContext} from "../../context/MainContext";

export default function UserForm() {
    const {setPageTitle} = useContext(MainContext)
    let { id} = useParams()
    const [active, setActive] = useState(true);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState("athlete" as any);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const objReg:any = {
            "id": id,
            "email": email,
            "currentPassword": currentPassword,
            "password": password,
            "username": "email",
            "identifier": 2,
    }

    useEffect(() => {
        setPageTitle("Cadastro de Usuários")
    }, []);

    return (
        <>
            <div className="col-md-12">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="active"
                           onChange={evento => setActive(evento.target.checked)}
                           checked={active}/>
                    <label className="form-check-label" htmlFor="active">Ativo</label>
                </div>
            </div>

            <div className="col-md-12">
                <label htmlFor="email" className="form-label">E-mail <i className="text-danger"> *</i></label>
                <input type="email" className="form-control" id="email" placeholder="Seu email"
                       onChange={evento => setEmail(evento.target.value)} value={email}/>
            </div>

            <div className="col-md-6">
                <label htmlFor="passwordConfirmation" className="form-label">Senha atual <i
                    className="text-danger"> *</i></label>
                <input type="password" className="form-control" id="passwordConfirmation" placeholder="******"
                       onChange={evento => setCurrentPassword(evento.target.value)}/>
            </div>

            <div className="col-md-6">
                <label htmlFor="password" className="form-label">Senha <i className="text-danger"> *</i></label>
                <input type="password" className="form-control" id="password" placeholder="******"
                       onChange={evento => setPassword(evento.target.value)}/>
            </div>

            <div className="col-md-6">
                <label htmlFor="passwordConfirmation" className="form-label">Confirme a senha <i
                    className="text-danger"> *</i></label>
                <input type="password" className="form-control" id="passwordConfirmation" placeholder="******"
                       onChange={evento => setPasswordConfirmation(evento.target.value)}/>
            </div>

            <div className="col-md-4">
                <label htmlFor="role" className="form-label">Permissão <i className="text-danger"> * </i></label>
                <Select
                    defaultValue={{label: 'Atleta', value: 'athlete'}}
                    options={[{label: 'Organizador', value: 'organizer'}, {
                        label: 'Cordenador',
                        value: 'coordinator'
                    }, {label: 'Atleta', value: 'athlete'}]}
                    value={[{label: 'Organizador', value: 'organizer'}, {label: 'Cordenador', value: 'coordinator'}, {
                        label: 'Atleta',
                        value: 'athlete'
                    }].find(option => option.value == role)}
                    name='role'
                    id='role'
                    onChange={(data: any) => {
                        setRole(data.value)
                    }}
                />
            </div>
        </>
    );
}

