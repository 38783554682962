import {createContext, ReactNode, useState} from 'react'

type Props = {
    children?: ReactNode;
}

let initialValue = {
    password: '',
    setPassword: () => {},
    passwordConfirmation: '',
    setPasswordConfirmation: () => {},
    currentPassword: '',
    setCurrentPassword: () => {},
}
const PasswordContext = createContext<any>(initialValue)

const PasswordProvider = ({children}: Props) => {
    const [password, setPassword] = useState(initialValue.password);
    const [passwordConfirmation, setPasswordConfirmation] = useState(initialValue.passwordConfirmation);
    const [currentPassword, setCurrentPassword] = useState(initialValue.currentPassword);

    const [upperCase, setUpperCase] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [numbers, setNumbers] = useState(false);
    const [special, setSpecial] = useState(false);
    const [size, setSize] = useState(false);

    const validatePasswordRegister = (change  = false) => {
        if (!password) {
            throw "Senha deve ser informada";
        }

        if (!passwordConfirmation) {
            throw "Confimação da senha deve ser informada";
        }

        if (change){
            if (!currentPassword) {
                throw "Sua senha atual deve ser informada";
            }
        }

        if(!validatePassword()){
           throw "Sua senha não atende as normas de seguraça de senha";
        }

    }

    const passwordIdent = () => {
         if (password != passwordConfirmation){
             throw "Senhas devem ser iguais!";
         }
    }

    const validatePassword = () => {
       return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s]).{8,}$/.test(password.replace(/\s/g, ''));
    }

    function checkPolity() {
        // Verificar se a senha tem pelo menos 8 caracteres
        if (password.replace(/\s/g, '').length < 8) {
            setSize(false)
        }else{
            setSize(true)
        }

        // Verificar se a senha tem pelo menos uma letra minúscula
        if (!/[a-z]/.test(password)) {
            setLowerCase(false)
        }else{
            setLowerCase(true)
        }

        // Verificar se a senha tem pelo menos uma letra maiúscula
        if (!/[A-Z]/.test(password)) {
            setUpperCase(false)
        }else{
            setUpperCase(true)
        }

        // Verificar se a senha tem pelo menos um número
        if (!/\d/.test(password)) {
            setNumbers(false)
        }else{
            setNumbers(true)
        }

        // Verificar se a senha tem pelo menos um caractere especial
        if (!/[^a-zA-Z0-9]/.test(password.replace(/\s/g, ''))) {
            setSpecial(false)
        }else{
            setSpecial(true)
        }
    }

    return (
     <>
        <PasswordContext.Provider value={{
            password, setPassword,
            passwordConfirmation, setPasswordConfirmation,
            currentPassword, setCurrentPassword,
            validatePasswordRegister,
            passwordIdent,
            validatePassword,
            checkPolity,
            upperCase, lowerCase,
            numbers, special, size
        }}>
            {children}
        </PasswordContext.Provider>
     </>
    )
}

export { PasswordContext, PasswordProvider }