import React, {useState} from "react";
import HabitualitytPrinting from "./HabitualitytPrinting";
import PrintingComponent from "../../components/printingComponent";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

export default function HabitualityReport({ athleteId}:any) {
    const [startDate, setStartDate] = useState(dayjs().add(-1, 'year') as any);
    const [endDate, setEndDate] = useState(dayjs() as any);

    const [print, setPrint] = useState<any>({})

    const childToParent = (childdata:any) => {
        setPrint({imprimir:false, athlete:null})
    }

    return (
        <>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#verticalycentered">
                <i className="bi bi-printer-fill"></i>
            </button>
            <div className="modal fade" id="verticalycentered" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Relatorio de habitualidades</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <div className="row g-3">

                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                            <div className="col-md-6">
                                                <label htmlFor="birthDate" className="form-label">Data inicial <i
                                                    className="text-danger"> *</i></label>
                                                <DatePicker  className={"form-control sadate"}
                                                             value={startDate}
                                                             onChange={evento => setStartDate(evento)}/>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="birthDate" className="form-label">Data final <i
                                                    className="text-danger"> *</i></label>
                                                <DatePicker  className={"form-control sadate"}
                                                             value={endDate}
                                                             onChange={evento => setEndDate(evento)}/>
                                            </div>
                                        </LocalizationProvider>

                                        <div className="col">
                                            <button type={"button"} className="btn btn-info"
                                                    onClick={() => setPrint({imprimir: true, athlete: athleteId})}>
                                                <i className="bi bi-printer"></i> Imprimir
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Sair</button>
                        </div>
                    </div>
                </div>
            </div>

            {print.imprimir &&
                <PrintingComponent
                    componentToPrint={<HabitualitytPrinting athleteId={print.athlete} filtro={{dataIni: (startDate != null ? dayjs(startDate).format('YYYY-MM-DD') : null) ,dataFim: (endDate != null ? dayjs(endDate).format('YYYY-MM-DD') : null)}}/>}
                    textButon={""} childToParent={childToParent}/>
            }
        </>
    );
}

