import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import {AuthProvider} from "./context/AuthContext";
import {MainProvider} from "./context/MainContext";
// import './index.css';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/style.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
          <MainProvider>
              <AuthProvider>
                    <Router />
              </AuthProvider>
          </MainProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
