import React, {useContext} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

export default function Navbar() {
    const navigate = useNavigate();
    const { setAuthenticated, setAuthUser, authUser } = useContext(AuthContext)

    const logout = () => {
        axios.post(`${process.env.REACT_APP_API_HOST}/api/logout`)
            .then(() => {
                localStorage.removeItem('token');
                setAuthenticated(false)
                setAuthUser({})
                return navigate("/login");
            }).catch(erro => Swal.fire({
                title: 'Erro!',
                text: 'Erro ao sair',
                icon: 'error',
            }))

    };
    return (
      <>
          <nav className="header-nav ms-auto">
              <ul className="d-flex align-items-center">

                  <li className="nav-item dropdown pe-3">

                      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                          {/*<img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/>*/}
                          <span className="d-none d-md-block dropdown-toggle ps-2"><i className="bi bi-person-circle"></i>&nbsp;</span>
                      </a>

                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                          <li className="dropdown-header">
                              <span>{authUser?.athlete?.full_name || "-"}</span>
                          </li>
                          <li>
                              <hr className="dropdown-divider"/>
                          </li>

                          <li>
                              <Link to={"/changePassoword"} className="dropdown-item d-flex align-items-center" >
                                  <i className="bi bi-key-fill"></i>
                                  <span>Alterar senha</span>
                              </Link>
                          </li>
                          {(authUser?.role === 'athlete') &&
                              <li>
                                  <Link className="dropdown-item d-flex align-items-center" to={"profile"}>
                                      <i className="bi bi-person"></i>
                                      <span>Meu perfil</span>
                                  </Link>
                              </li>
                          }
                          <li>
                              <hr className="dropdown-divider"/>
                          </li>

                          <li>
                              <a className="dropdown-item d-flex align-items-center" onClick={logout} href="#">
                                  <i className="bi bi-box-arrow-right"></i>
                                  <span>Sair</span>
                              </a>
                          </li>

                      </ul>

                  </li>

              </ul>
          </nav>
      </>
    );
}