import React, {useContext, useEffect, useState} from "react";
import api from "../../services/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {AddressContext} from "../../context/Address/AddressContext";
import {AthleteContext} from "../../context/Athele/AthleteContext";
import {MainContext} from "../../context/MainContext";
import AddressForm from "../Address/form";
import PresidentForm from "./formPresident";
import AtForm from "./formAthlete";
import CacForm from "./formCac";
import dayjs from "dayjs";
import {AuthContext} from "../../context/AuthContext";


export default function AtheleForm() {
    const navigate = useNavigate()
    const { authUser, authenticated, getRefreshToken} = useContext(AuthContext)

    let { id} = useParams()

    if (authUser?.role === 'athlete' ){
        id = authUser?.athlete?.id
    }

    const [userId, setUser] = useState(0)

    const [email, setEmail] = useState('');

    const { active, setActive,
        setIncompleteRegistration,
        fullName,setFullName,
        cpf, setCpf,
        setRg,
        setGenre,
        setPhone,
        setBirthDate,
        setLevel,
        setCrNumber,
        setCrExpedition,
        setCrValidity,
        setPhoneDispatcher,
        setEmailDispatcher,
        setMemberNumber,
        setMembershipDate,
        setDisaffiliationDate,
        setMembershipType,
        dispatchAthleteObject,validateAthleteRegister} = useContext(AthleteContext)

    const {setAddress,
        setZipCode,
        setStreet,
        setNumber,
        setDistrict,
        setComplement,
        setCity,
        dispatchAddressObject,
        validateAddressRegister} = useContext(AddressContext)

    const objReg:any = {
        ...dispatchAthleteObject,
        "user": {
            "active": active,
            "email": email,
            "username": fullName.split(' ')[0],
            "identifier": cpf
        },
        "address": dispatchAddressObject
    }

    const {setLoading} = useContext(MainContext)

    useEffect(() => {
        setLoading(true)

        const fetchData = async () => {
            if (id != undefined) {
                await api.get('/api/athletes/' + id).then(resposta => {
                        const athlete = resposta.data

                        setUser(athlete?.user?.id)
                        setAddress(athlete?.address?.id)

                        setEmail(athlete.user?.email || '')

                        setEmailDispatcher(athlete?.email_dispatcher)
                        setPhoneDispatcher(athlete?.phone_dispatcher)

                        setMembershipType(athlete?.membership_type)

                        setMembershipDate(athlete?.membership_date != null ? dayjs(athlete?.membership_date) : null)
                        setMemberNumber(athlete?.member_number)
                        setDisaffiliationDate(athlete?.disaffiliation_date != null ? dayjs(athlete?.disaffiliation_date) : null)

                        setLevel(athlete?.level_id)
                        setCrNumber(athlete?.cr_number)


                        setCrValidity(athlete?.cr_validity_date != null ? dayjs(athlete?.cr_validity_date) : null)
                        setCrExpedition(athlete?.cr_expedition_date != null ? dayjs(athlete?.cr_expedition_date) : null)

                        setActive(athlete?.active)
                        setFullName(athlete?.full_name)
                        setCpf(athlete?.cpf)
                        setRg(athlete?.rg)
                        setPhone(athlete?.phone)
                        setBirthDate(athlete?.birth_date != null ? dayjs(athlete?.birth_date) : null)
                        setGenre(athlete?.genre)

                        setZipCode(athlete?.address.zip_code)
                        setStreet(athlete?.address.street)
                        setNumber(athlete?.address.number)
                        setDistrict(athlete?.address.district)
                        setComplement(athlete?.address.complement)

                        if(athlete?.address.city_id){
                            setCity({
                                value: athlete?.address.city_id,
                                label: athlete?.address?.city?.name + ' - ' + athlete?.address?.city?.state.abbreviation,
                            })
                        }

                    })
            }
        };

        fetchData().then(setLoading(false))
    },  [])

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();
            if (objReg.user.email == ""){
                objReg.incomplete_registration = 1
                setIncompleteRegistration(1)
            } else {
                objReg.incomplete_registration = 0
                setIncompleteRegistration(0)
            }

            if (id == undefined){
                objReg.user.id = userId
            }

            validateAthleteRegister()
            if (authUser?.role === 'athlete' && !authenticated){
                validateAddressRegister()
            }

            if (id == undefined && authUser?.role !== 'athlete') {
                api.post('/api/users', objReg).then((result) => {
                  Swal.fire({
                    title: 'Sucesso!',
                    text: result.data.message,
                    icon: 'success',
                    timer: 5000
                  }).then((result) => {
                      return navigate("/members");
                  });
                })
                  .catch(erro => {
                      Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                      })
                    }
                  );

            } else {
                api.put('/api/athletes/' + id, objReg).then((result) => {
                    if (authUser?.role === 'athlete') {
                        getRefreshToken()
                    }
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        return navigate(authUser?.role === 'athlete' ? "/" : "/members");
                    });
                })
                    .catch(erro  => Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                    })
                    )
            }
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className="row g-3">
                {(authUser?.role !== 'athlete') &&
                <div className="col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="active"
                               onChange={evento => setActive(evento.target.checked)} checked={active}/>
                        <label className="form-check-label" htmlFor="active">Ativo</label>
                    </div>
                </div>
                }

                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">
                            <PresidentForm/>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">

                            <div className="col-md-12">
                                <label htmlFor="email" className="form-label">E-mail {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                                <input type="email" className="form-control" id="email"
                                       placeholder="Seu email"
                                       onChange={evento => setEmail(evento.target.value)} value={email}/>
                            </div>

                            <AtForm/>
                        </div>
                    </div>
                </div>

                <h3>CAC</h3>

                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">
                            <CacForm/>
                        </div>
                    </div>
                </div>

                <h3>Endereço</h3>

                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">
                            <AddressForm/>
                        </div>
                    </div>
                </div>

                <div className="">
                    <button type="submit" className="btn btn-success me-2">Salvar</button>
                    <Link to={authUser?.role === 'athlete' ? "/" : "/members"} className="btn btn-danger">Voltar</Link>
                </div>
            </form>
        </>
);
}

