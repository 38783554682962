import React from "react";

export default function TermRegister() {
    return (
        <>
            <div className="elementor-widget-container">

                <p><strong>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</strong></p><p>
                <strong>&nbsp;</strong>
            </p>
                <ol>
                    <li><strong>Objetivo<br/><br/></strong></li>
                </ol>
                <p>A Política de Segurança da Informação e Privacidade é uma
                    declaração formal
                    {/*da empresa <strong>SAS</strong> */}
                    que estabelece o seu compromisso com
                    a segurança
                    das
                    informações, a proteção e o correto tratamento de dados pessoais, que são
                    utilizados em seus
                    processos
                    operacionais e gerenciais durante todo o ciclo de vida.</p><p
            >Este
                documento orienta e estabelece as diretrizes a serem observadas por todos os
                envolvidos direta
                ou
                indiretamente nas atividades da organização, visando a garantia da
                confidencialidade,
                integridade,
                disponibilidade e autenticidade das informações, bem como o respeito aos direitos
                dos titulares
                de dados
                pessoais quanto ao tratamento desses dados. Desta forma as diretrizes aqui
                apresentadas orientam
                sobre as
                atitudes adequadas a serem adotadas para o manuseio, tratamento, controle e proteção
                de dados
                pessoais,
                informações, documentos e conhecimentos coletados, produzidos, armazenados e
                transmitidos nas
                atividades da
                organização contra ameaças e vulnerabilidades relacionadas à segurança da informação
                e à
                privacidade.<br/><br/><br/></p>
            {/*    <ol>*/}
            {/*        <li><strong>Escopo e Abrangência </strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><br/>A Política de Segurança da Informação e Privacidade é*/}
            {/*        composta por*/}
            {/*        normas, diretrizes, procedimentos e responsabilidades, e deve ser seguida por*/}
            {/*        todos os*/}
            {/*        dirigentes,*/}
            {/*        colaboradores e terceiros que estejam de alguma forma realizando atividades que*/}
            {/*        possam gerar*/}
            {/*        algum risco*/}
            {/*        a garantia da confidencialidade, integridade, disponibilidade e autenticidade*/}
            {/*        das*/}
            {/*        informações da*/}
            {/*        organização, bem como a proteção de dados pessoais, estejam essas atividades*/}
            {/*        ocorrendo*/}
            {/*        interna ou*/}
            {/*        externamente ao ambiente físico da organização.<br/><br/><br/></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Termos e definições</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><strong>&nbsp;</strong></p>*/}
            {/*    <ul>*/}
            {/*        <li>3.1. <strong>Ativo:</strong> tudo que manipula dados ou*/}
            {/*            informações,*/}
            {/*            inclusive ela própria, tais como planos, processos, procedimentos, bases de*/}
            {/*            dados,*/}
            {/*            arquivos,*/}
            {/*            documentação de sistema, manuais, material de treinamento, informações*/}
            {/*            armazenadas,*/}
            {/*            softwares,*/}
            {/*            sistemas, ferramentas, utilitários, estações de trabalho, servidores,*/}
            {/*            equipamentos de*/}
            {/*            comunicação,*/}
            {/*            nobreaks e outros;<br/><br/></li>*/}
            {/*        <li>3.2. <strong>Agentes de tratamento:</strong> o controlador*/}
            {/*            e o*/}
            {/*            operador;<br/><br/></li>*/}
            {/*        <li>3.3. <strong>Ativo de informação:</strong> ativo que*/}
            {/*            armazena dados ou*/}
            {/*            informações;<br/><br/></li>*/}
            {/*        <li>3.4. <strong>Comitê de Segurança da Informação*/}
            {/*            (CSI):</strong> grupo de*/}
            {/*            pessoas com a responsabilidade de assessorar a implementação das ações de*/}
            {/*            segurança da*/}
            {/*            informação e*/}
            {/*            comunicações no âmbito da organização;<br/><br/></li>*/}
            {/*        <li>3.5. <strong>Controlador:</strong> pessoa natural ou*/}
            {/*            jurídica, de*/}
            {/*            direito público ou privado, a quem competem as decisões referentes ao*/}
            {/*            tratamento de*/}
            {/*            dados*/}
            {/*            pessoais;<br/><br/></li>*/}
            {/*        <li>3.6. <strong>Dado pessoal:</strong> informação relacionada*/}
            {/*            a pessoa*/}
            {/*            natural identificada ou identificável;<br/><br/></li>*/}
            {/*        <li>3.7. <strong>Dado pessoal sensível:</strong> dado pessoal*/}
            {/*            sobre origem*/}
            {/*            racial ou étnica, convicção religiosa, opinião política, filiação a*/}
            {/*            sindicato ou a*/}
            {/*            organização de*/}
            {/*            caráter religioso, filosófico ou político, dado referente à saúde ou à vida*/}
            {/*            sexual, dado*/}
            {/*            genético ou*/}
            {/*            biométrico, quando vinculado a uma pessoa natural;<br/><br/></li>*/}
            {/*        <li>3.8. <strong>Dado anonimizado:</strong> dado relativo a*/}
            {/*            titular que não*/}
            {/*            possa ser identificado, considerando a utilização de meios técnicos*/}
            {/*            razoáveis e*/}
            {/*            disponíveis na*/}
            {/*            ocasião de seu tratamento;<br/><br/></li>*/}
            {/*        <li>3.9. <strong>Dado pseudoanonimizado:</strong> consiste num*/}
            {/*            mecanismo de*/}
            {/*            disfarce da identidade, substituindo-se um atributo por outro.<br/><br/>*/}
            {/*        </li>*/}
            {/*        <li>3.10. <strong>Encarregado:</strong> pessoa indicada pelo*/}
            {/*            controlador e*/}
            {/*            operador para atuar como canal de comunicação entre o controlador, os*/}
            {/*            titulares dos*/}
            {/*            dados e a*/}
            {/*            Autoridade Nacional de Proteção de Dados (ANPD);<br/><br/></li>*/}
            {/*        <li>3.11. <strong>Incidente de Segurança da*/}
            {/*            Informação:</strong> qualquer*/}
            {/*            evento adverso, confirmado ou sob suspeita, relacionado à segurança de*/}
            {/*            sistemas de*/}
            {/*            informação,*/}
            {/*            levando à perda de um ou mais princípios básicos de Segurança da Informação:*/}
            {/*            autenticidade,*/}
            {/*            confidencialidade, integridade e disponibilidade;<br/><br/></li>*/}
            {/*        <li>3.12. <strong>Incidente de Privacidade:</strong> qualquer*/}
            {/*            evento*/}
            {/*            adverso, confirmado ou sob suspeita, relacionado à proteção de dados*/}
            {/*            pessoais, levando à*/}
            {/*            perda de um*/}
            {/*            ou mais princípios básicos de Proteção de Dados Pessoais: finalidade,*/}
            {/*            adequação,*/}
            {/*            necessidade, livre*/}
            {/*            acesso, qualidade dos dados, transparência, segurança, prevenção, não*/}
            {/*            discriminação,*/}
            {/*            responsabilização e prestação de contas;<br/><br/></li>*/}
            {/*        <li>3.13. <strong>Informação:</strong> resultante de*/}
            {/*            processamento,*/}
            {/*            manipulação e organização de dados, de tal forma que represente uma*/}
            {/*            modificação*/}
            {/*            (quantitativa ou*/}
            {/*            qualitativa) no conhecimento do sistema (humano ou computacional) que a*/}
            {/*            recebe;<br/><br/></li>*/}
            {/*        <li>3.14. <strong>Medidas de proteção:</strong> medidas*/}
            {/*            destinadas a*/}
            {/*            garantir o sigilo, quando necessário, a inviolabilidade, à integridade, a*/}
            {/*            autenticidade,*/}
            {/*            a*/}
            {/*            legitimidade e a disponibilidade de dados e informações, com o objetivo de*/}
            {/*            prevenir,*/}
            {/*            detectar,*/}
            {/*            anular ou registrar ameaças reais ou potenciais a dados e*/}
            {/*            informações;<br/><br/></li>*/}
            {/*        <li>3.15. <strong>Não-repúdio:</strong> garantia de que o*/}
            {/*            emissor da*/}
            {/*            mensagem não irá negar posteriormente a autoria da mensagem ou transação,*/}
            {/*            permitindo a*/}
            {/*            sua*/}
            {/*            identificação;<br/><br/></li>*/}
            {/*        <li>3.16. <strong>Operador:</strong> pessoa natural ou*/}
            {/*            jurídica, de direito*/}
            {/*            público ou privado, que realiza o tratamento de dados pessoais em nome do*/}
            {/*            controlador;<br/><br/>*/}
            {/*        </li>*/}
            {/*        <li>3.17. <strong>Plano de Continuidade do*/}
            {/*            Negócio:</strong> descreve as*/}
            {/*            ações a serem tomadas para assegurar a continuidade dos processos críticos*/}
            {/*            em caso de*/}
            {/*            sinistros na*/}
            {/*            organização ou falhas nos sistemas, incluindo a ativação de processos*/}
            {/*            manuais,*/}
            {/*            duplicidade de*/}
            {/*            recursos, traslado de pessoal e acionamento de prestadores de*/}
            {/*            serviço;<br/><br/></li>*/}
            {/*        <li>3.18. <strong>Política de Segurança da*/}
            {/*            Informação:</strong> recomendações com o propósito de estabelecer critérios*/}
            {/*            para o*/}
            {/*            adequado*/}
            {/*            manuseio, armazenamento, transporte e descarte das informações por meio do*/}
            {/*            desenvolvimento de*/}
            {/*            diretrizes, normas, procedimentos e instruções destinadas, respectivamente,*/}
            {/*            aos níveis*/}
            {/*            estratégico,*/}
            {/*            tático e operacional;<br/><br/></li>*/}
            {/*        <li>3.19. <strong>Prestadores de Serviço:</strong> pessoa*/}
            {/*            jurídica ou*/}
            {/*            física que mantenha contrato de prestação de serviço com a organização;<br/><br/>*/}
            {/*        </li>*/}
            {/*        <li>3.20. <strong>Proprietário da*/}
            {/*            Informação:</strong> responsável pela*/}
            {/*            classificação e autorização do acesso à informação;<br/><br/></li>*/}
            {/*        <li>3.21. <strong>Segurança da Informação:</strong> conjunto de*/}
            {/*            controles*/}
            {/*            que visam garantir a preservação dos aspectos de confidencialidade,*/}
            {/*            integridade e*/}
            {/*            disponibilidade*/}
            {/*            das informações;<br/><br/></li>*/}
            {/*        <li>3.22. <strong>Sigilo:</strong> propriedade da informação*/}
            {/*            que indica o*/}
            {/*            impedimento de acesso a ela por pessoa não autorizada;<br/><br/></li>*/}
            {/*        <li>3.23. <strong>Termo de Responsabilidade:</strong> documento*/}
            {/*            que*/}
            {/*            formaliza a obrigação de colaboradores e terceiros quanto a guarda e*/}
            {/*            tratamento das*/}
            {/*            informações, de*/}
            {/*            acordo com seu nível de confidencialidade estabelecido, e a correta*/}
            {/*            utilização dos*/}
            {/*            recursos*/}
            {/*            computacionais disponibilizados pela organização;<br/><br/></li>*/}
            {/*        <li>3.24. <strong>Titular:</strong> pessoa natural a quem se*/}
            {/*            referem os*/}
            {/*            dados pessoais que são objeto de tratamento;<br/><br/></li>*/}
            {/*        <li>3.25. <strong>Usuário:</strong> pessoa que utiliza os*/}
            {/*            recursos e*/}
            {/*            serviços de tecnologia da informação (TI) no dia a dia, podendo ser*/}
            {/*            colaborador,*/}
            {/*            contratado por*/}
            {/*            tempo determinado, prestador de serviço terceirizado, estagiários, dentre*/}
            {/*            outros.*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <p><strong>&nbsp;<br/><br/></strong></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Princípios</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><strong>&nbsp;</strong></p><p>A organização atua em conformidade*/}
            {/*    com as*/}
            {/*    diretrizes estabelecidas nesta Política de Segurança da Informação e Privacidade,*/}
            {/*    observando os*/}
            {/*    princípios*/}
            {/*    da legalidade, da impessoalidade, da moralidade, da publicidade, da eficiência, da*/}
            {/*    finalidade,*/}
            {/*    do interesse*/}
            {/*    público, da transparência e da motivação dos atos administrativos. Desta forma, o*/}
            {/*    uso indevido,*/}
            {/*    negligente*/}
            {/*    ou imprudente dos recursos e serviços concedidos aos seus colaboradores e terceiros*/}
            {/*    será objeto*/}
            {/*    de análise*/}
            {/*    de dados e evidências para obtenção de provas a serem utilizadas em processos*/}
            {/*    investigatórios,*/}
            {/*    bem como para*/}
            {/*    adoção das medidas legais cabíveis.</p>*/}
            {/*    <ul>*/}
            {/*        <li><strong>4.1. </strong><strong>Princípios da Segurança da*/}
            {/*            Informação:</strong></li>*/}
            {/*    </ul>*/}
            {/*    <p>Com o objetivo de garantir a segurança da informação, a*/}
            {/*        organização busca*/}
            {/*        atender aos seguintes princípios:</p><p>*/}
            {/*    <strong>Confidencialidade</strong>:*/}
            {/*    garantia do acesso reservado ao ativo de informação somente por pessoas autorizadas;*/}
            {/*</p><p*/}
            {/*><strong>Integridade: </strong>garantia de que a informação seja*/}
            {/*    mantida em seu*/}
            {/*    estado original, visando protegê-la, na guarda ou transmissão, contra alterações*/}
            {/*    indevidas,*/}
            {/*    intencionais ou*/}
            {/*    acidentais;</p><p><strong>Disponibilidade</strong>: garantia de que*/}
            {/*    os usuários*/}
            {/*    possam ter acesso a informações e aos ativos correspondentes no momento*/}
            {/*    oportuno;<br/><br/></p>*/}
            {/*    <ul>*/}
            {/*        <li><strong>4.2. </strong><strong>Princípios da Privacidade:</strong></li>*/}
            {/*    </ul>*/}
            {/*    <p>Com o objetivo de garantir a privacidade dos titulares dos dados*/}
            {/*        pessoais, a*/}
            {/*        organização busca atender aos seguintes princípios conforme art. 6° da LGPD:</p>*/}
            {/*    <p*/}
            {/*    ><strong>Finalidade</strong>: realização do tratamento para*/}
            {/*        propósitos*/}
            {/*        legítimos, específicos, explícitos e informados ao titular, sem possibilidade de*/}
            {/*        tratamento*/}
            {/*        posterior de*/}
            {/*        forma incompatível com essas finalidades;</p><p>*/}
            {/*    <strong>Adequação</strong>:*/}
            {/*    compatibilidade do tratamento com as finalidades informadas ao titular, de acordo*/}
            {/*    com o contexto*/}
            {/*    do*/}
            {/*    tratamento;</p><p><strong>Necessidade</strong>: limitação do*/}
            {/*    tratamento ao*/}
            {/*    mínimo necessário para a realização de suas finalidades, com abrangência dos dados*/}
            {/*    pertinentes,*/}
            {/*    proporcionais e não excessivos em relação às finalidades do tratamento de dados;</p>*/}
            {/*    <p*/}
            {/*    ><strong>Livre acesso</strong>: garantia, aos titulares, de consulta*/}
            {/*        facilitada*/}
            {/*        e gratuita sobre a forma e a duração do tratamento, bem como sobre a*/}
            {/*        integralidade de seus dados*/}
            {/*        pessoais;*/}
            {/*    </p><p><strong>Qualidade dos dados</strong>: garantia, aos titulares,*/}
            {/*    de exatidão,*/}
            {/*    clareza, relevância e atualização dos dados, de acordo com a necessidade e para o*/}
            {/*    cumprimento da*/}
            {/*    finalidade*/}
            {/*    de seu tratamento;</p><p><strong>Transparência</strong>: garantia,*/}
            {/*    aos*/}
            {/*    titulares, de informações claras, precisas e facilmente acessíveis sobre a*/}
            {/*    realização do*/}
            {/*    tratamento e os*/}
            {/*    respectivos agentes de tratamento, observados os segredos comercial e industrial;*/}
            {/*</p><p*/}
            {/*><strong>Segurança</strong>: utilização de medidas técnicas e*/}
            {/*    administrativas*/}
            {/*    aptas a proteger os dados pessoais de acessos não autorizados e de situações*/}
            {/*    acidentais ou*/}
            {/*    ilícitas de*/}
            {/*    destruição, perda, alteração, comunicação ou difusão;</p><p>*/}
            {/*    <strong>Prevenção</strong>: adoção de medidas para prevenir a ocorrência de danos em*/}
            {/*    virtude do*/}
            {/*    tratamento*/}
            {/*    de dados pessoais;</p><p><strong>Não discriminação</strong>:*/}
            {/*    impossibilidade de*/}
            {/*    realização do tratamento para fins discriminatórios ilícitos ou abusivos;</p><p*/}
            {/*><strong>Responsabilização e prestação de contas</strong>:*/}
            {/*    demonstração, pelo*/}
            {/*    agente, da adoção de medidas eficazes e capazes de comprovar a observância e o*/}
            {/*    cumprimento das*/}
            {/*    normas de*/}
            {/*    proteção de dados pessoais e, inclusive, da eficácia dessas medidas.<br/><br/><br/>*/}
            {/*</p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Bases legais para o tratamento de dados pessoais:</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><strong>&nbsp;<br/></strong>Visando a conformidade legal a*/}
            {/*        organização*/}
            {/*        realiza atividade de tratamento de dados pessoais somente nas seguintes*/}
            {/*        hipóteses, conforme*/}
            {/*        art. 7° da*/}
            {/*        LGPD:</p>*/}
            {/*    <ul>*/}
            {/*        <li>Mediante o fornecimento de consentimento pelo titular;</li>*/}
            {/*        <li>Cumprimento de obrigação legal ou regulatória pelo controlador;</li>*/}
            {/*        <li>Pela administração pública, para o tratamento e uso compartilhado de dados*/}
            {/*            necessários à*/}
            {/*            execução de*/}
            {/*            políticas públicas previstas em leis e regulamentos ou respaldadas em*/}
            {/*            contratos,*/}
            {/*            convênios ou*/}
            {/*            instrumentos congêneres;*/}
            {/*        </li>*/}
            {/*        <li>Para a realização de estudos por órgão de pesquisa, garantida, sempre que*/}
            {/*            possível, a*/}
            {/*            anonimização*/}
            {/*            dos dados pessoais;*/}
            {/*        </li>*/}
            {/*        <li>Quando necessário para a execução de contrato ou de procedimentos*/}
            {/*            preliminares*/}
            {/*            relacionados a*/}
            {/*            contrato do qual seja parte o titular, a pedido do titular dos dados;*/}
            {/*        </li>*/}
            {/*        <li>Para o exercício regular de direitos em processo judicial, administrativo ou*/}
            {/*            arbitral;*/}
            {/*        </li>*/}
            {/*        <li>Para a proteção da vida ou da incolumidade física do titular ou de*/}
            {/*            terceiros;*/}
            {/*        </li>*/}
            {/*        <li>Tutela da saúde, exclusivamente, em procedimento realizado por profissionais*/}
            {/*            de saúde,*/}
            {/*            serviços de*/}
            {/*            saúde ou autoridade sanitária;*/}
            {/*        </li>*/}
            {/*        <li>Para atender aos interesses legítimos do controlador ou de terceiros, exceto*/}
            {/*            no caso de*/}
            {/*            prevalecer*/}
            {/*            em direitos e liberdades fundamentais do titular que exijam a proteção dos*/}
            {/*            dados*/}
            {/*            pessoais;*/}
            {/*        </li>*/}
            {/*        <li>Para a proteção do crédito.</li>*/}
            {/*    </ul>*/}
            {/*    <p><strong>&nbsp;<br/><br/></strong></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Dados pessoais que podemos coletar, armazenar através*/}
            {/*            de</strong><strong> coleta*/}
            {/*            dos*/}
            {/*            assinantes do software e a sua utilização.<br/><br/></strong></li>*/}
            {/*    </ol>*/}
            {/*    <p>As informações referentes a pessoas físicas somente devem ser*/}
            {/*        coletadas na*/}
            {/*        medida da necessidade para a prestação de serviços, e em todas as hipóteses*/}
            {/*        cabíveis para o*/}
            {/*        tratamento*/}
            {/*        dos dados deverá ser obtido em conformidade com a Lei Geral de Proteção de*/}
            {/*        Dados.</p><p*/}
            {/*>No limite estabelecido pela legislação aplicável, às categorias e*/}
            {/*    tipos de*/}
            {/*    dados pessoais coletados pela SAS, podem incluir, observados os*/}
            {/*    princípios*/}
            {/*    norteadores elencados*/}
            {/*    no item anterior:&nbsp;</p>*/}
            {/*    <ul>*/}
            {/*        <li>Suas informações de contato, tais como como nome, nome da empresa, endereço*/}
            {/*            de e-mail,*/}
            {/*            endereço*/}
            {/*            telefônico ou postal, mas sem se limitar àquelas utilizadas para nos*/}
            {/*            comunicarmos com*/}
            {/*            você;*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Para competições: Nome completo, Número de identificação do atirador,*/}
            {/*            Filiação a uma*/}
            {/*            associação ou*/}
            {/*            clube de tiro;*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Para lojistas: Nome completo do cliente; Endereço de e-mail; Número de*/}
            {/*            telefone;*/}
            {/*            Informações de*/}
            {/*            Endereço e Histórico de Compras;*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Para despachante: Nome completo do requerente, data de nascimento, número de*/}
            {/*            identificação pessoal,*/}
            {/*            endereço residencial, informações de contato, estado civil do requerente,*/}
            {/*            histórico*/}
            {/*            criminal,*/}
            {/*            comprovação de residência, histórico de saúde mental.*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Para clubes e financeiro: Informações de identificação, sem sem se limitar*/}
            {/*            àquelas*/}
            {/*            utilizadas para*/}
            {/*            nos comunicarmos com você, histórico de filiação, informações de*/}
            {/*            treinamento, informação*/}
            {/*            de armas de*/}
            {/*            fogo possuídas, registro de participação em eventos ou competições;*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Informações de conta, como nome de usuário, ID de usuário, dados sobre seu*/}
            {/*            registro no*/}
            {/*            software da*/}
            {/*            SH;&nbsp;&nbsp;</li>*/}
            {/*    </ul>*/}
            {/*    <ul>*/}
            {/*        <li>Informações profissionais ou relacionadas a emprego, tais profissionais,*/}
            {/*            currículo,*/}
            {/*            carta de*/}
            {/*            apresentação;<br/><br/></li>*/}
            {/*    </ul>*/}
            {/*    <p>Os dados pessoais também podem ser obtidos diretamente pela*/}
            {/*        SAS*/}
            {/*        por meio de interações e por meio de seus produtos e serviços, sendo que os*/}
            {/*        dados pessoais*/}
            {/*        coletados*/}
            {/*        online também podem ser combinados com os dados pessoais fornecidos por meio de*/}
            {/*        canais*/}
            {/*        offline, como,*/}
            {/*        por exemplo, durante uma reunião, entrevistas de trabalho, eventos realizados e*/}
            {/*        ainda, de*/}
            {/*        forma*/}
            {/*        automática, relacionadas ao uso de nossos sites e resposta aos nossos e-mails*/}
            {/*        por meio do*/}
            {/*        uso de várias*/}
            {/*        tecnologias.</p><p>Os dados poderão ser usados para a*/}
            {/*    identificação e*/}
            {/*    autenticação do usuário quando do acesso aos serviços da SAS, no*/}
            {/*    processamento de*/}
            {/*    informações*/}
            {/*    pessoais a fim de cumprir com as obrigações contratuais firmadas respectivamente com*/}
            {/*    colaboradores,*/}
            {/*    terceiros, parceiros e clientes, sempre visando garantir a segurança dos dados*/}
            {/*    pessoais.</p><p*/}
            {/*><strong>&nbsp;<br/><br/></strong></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Responsabilidade compartilhada.<br/><br/></strong></li>*/}
            {/*    </ol>*/}
            {/*    <p>A responsabilidade pelo correto tratamento dos dados pessoais*/}
            {/*        dentro da*/}
            {/*        empresa é compartilhada entre todos aqueles que atuam como operadores, sendo*/}
            {/*        fundamental a*/}
            {/*        cooperação de*/}
            {/*        todos para que a empresa esteja sempre em conformidade com a lei, oferecendo*/}
            {/*        segurança a*/}
            {/*        todos os dados*/}
            {/*        pessoais sob seu controle.</p><p>A SAS poderá*/}
            {/*    divulgar dados*/}
            {/*    pessoais com parceiros de negócios e provedores de serviços para apoiar nossas*/}
            {/*    operações. Tais*/}
            {/*    parceiros de*/}
            {/*    negócios e provedores de serviços são obrigados por contrato a manter as informações*/}
            {/*    recebidas*/}
            {/*    em nome da*/}
            {/*    SAS confidenciais e seguras e não usá-las para qualquer propósito*/}
            {/*    diferente daquele*/}
            {/*    para o qual*/}
            {/*    foram fornecidos a eles.</p><p>O compartilhamento de dados pessoais*/}
            {/*    com pessoas*/}
            {/*    ou entidades externas à SAS deve ser restrito ao mínimo necessário para a*/}
            {/*    execução*/}
            {/*    dos contratos*/}
            {/*    e prestações de serviços nos quais os titulares estão envolvidos, ou para o*/}
            {/*    cumprimento de*/}
            {/*    qualquer*/}
            {/*    obrigação legal. Mesmo quando o tratamento envolver diretamente a prestação de*/}
            {/*    serviços, será*/}
            {/*    atribuída a*/}
            {/*    base legal adequada para este tratamento e compartilhamento.</p><p*/}
            {/*>O Clube de Tapejara, pode ainda, divulgar dados pessoais conforme exigido por Lei ou processo*/}
            {/*    legal, e que*/}
            {/*    sejam*/}
            {/*    imprescindíveis para o cumprimento de determinações legais, judiciais e*/}
            {/*    administrativas e/ou*/}
            {/*    para o*/}
            {/*    exercício do direito de defesa em processos judiciais e administrativos, os quais*/}
            {/*    serão*/}
            {/*    mantidas, a despeito*/}
            {/*    da exclusão dos demais dados.&nbsp;</p><p>Nos termos dos art. 42 e*/}
            {/*    seguintes da*/}
            {/*    Lei Geral de Proteção de Dados (Lei 13.709 de 14 de agosto de 2018), a operador de*/}
            {/*    dados*/}
            {/*    pessoais que*/}
            {/*    descumprir as diretrizes lícitas de proteção de dados do controlador – no caso a*/}
            {/*    SAS*/}
            {/*    – responderá*/}
            {/*    solidariamente, estando assim sujeito à responsabilidade civil, administrativa e*/}
            {/*    criminal sobre*/}
            {/*    o tratamento*/}
            {/*    inadequado dos dados.</p><p>É vedado o compartilhamento externo de*/}
            {/*    dados*/}
            {/*    pessoais de clientes ou membros da empresa – por qualquer meio, telefônico, digital*/}
            {/*    ou por*/}
            {/*    escrito – sem*/}
            {/*    autorização destes, sendo dada a ciência devida ao titular sempre que os dados forem*/}
            {/*    compartilhados em um*/}
            {/*    novo contexto.<br/><br/><br/></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Tratamento dos dados pessoais na SAS.<br/><br/></strong>*/}
            {/*        </li>*/}
            {/*    </ol>*/}
            {/*    <p>O tratamento dos dados pessoais na SAS deve seguir os*/}
            {/*        princípios*/}
            {/*        definidos nesta política, devendo ser estritamente voltado às finalidades às*/}
            {/*        quais a coleta*/}
            {/*        dos dados se*/}
            {/*        destina, respeitando os princípios desta política e os critérios de*/}
            {/*        compartilhamento e de*/}
            {/*        segurança das*/}
            {/*        informações e a legislação pertinente.</p><p>Os dados pessoais*/}
            {/*    devem ser*/}
            {/*    tratados apenas por pessoas que precisem lidar com eles. Assim, reduzem-se os riscos*/}
            {/*    de falhas*/}
            {/*    humanas*/}
            {/*    propiciando um vazamento ou uso inadequado da informação. A melhor forma de garantir*/}
            {/*    isso é*/}
            {/*    dividindo os*/}
            {/*    dados por setores, e por responsabilidades específicas dentro de cada setor. Assim*/}
            {/*    se saberá em*/}
            {/*    cada*/}
            {/*    situação quem são os operadores dos dados, e os riscos de um incidente na segurança*/}
            {/*    da*/}
            {/*    informação diminui*/}
            {/*    consideravelmente.</p><p>Para garantir este tratamento setorizado*/}
            {/*    dos dados, o*/}
            {/*    acesso de cada empregado ou prestador de serviço da SAS ao banco de dados*/}
            {/*    da empresa*/}
            {/*    é individual*/}
            {/*    e protegido por senha própria e intransferível. Assim, somente as pessoas*/}
            {/*    autorizadas a*/}
            {/*    manipular dados*/}
            {/*    pessoais identificáveis de funcionários e contratados, por exemplo, poderão*/}
            {/*    acessá-los.</p><p*/}
            {/*>O mero acesso e/ou a utilização indevida de quaisquer dados*/}
            {/*    pessoais*/}
            {/*    armazenados nos resíduos tecnológicos processados pela empresa são terminantemente*/}
            {/*    proibidos,*/}
            {/*    sob pena de*/}
            {/*    demissão por justa causa (ou rescisão do contrato de prestação de serviços) sem*/}
            {/*    prejuízo da*/}
            {/*    responsabilização cível e criminal cabível em âmbito judiciário.<br/><br/><br/></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Período em que os dados serão armazenados.</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><br/>Os dados pessoais coletados pela SAS serão*/}
            {/*        utilizados e*/}
            {/*        armazenados durante o tempo necessário para a prestação do serviço ou para que*/}
            {/*        as*/}
            {/*        finalidades elencadas*/}
            {/*        na presente Política de Privacidade sejam atingidas, considerando os direitos*/}
            {/*        dos titulares*/}
            {/*        dos dados e*/}
            {/*        dos controladores. Assim, os dados serão mantidos enquanto a relação contratual*/}
            {/*        entre o*/}
            {/*        titular e a*/}
            {/*        SAS perdurar e, findo o período de armazenamento dos dados pessoais,*/}
            {/*        estes serão*/}
            {/*        excluídos de*/}
            {/*        nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente*/}
            {/*        previstas no*/}
            {/*        artigo 16 LGPD,*/}
            {/*        a saber:</p><p>I – cumprimento de obrigação legal ou*/}
            {/*    regulatória pelo*/}
            {/*    controlador;</p><p>II – estudo por órgão de pesquisa, garantida,*/}
            {/*    sempre que*/}
            {/*    possível, a anonimização dos dados pessoais;</p><p>III –*/}
            {/*    transferência a*/}
            {/*    terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta*/}
            {/*    Lei; ou</p>*/}
            {/*    <p*/}
            {/*    >IV – uso exclusivo do controlador, vedado seu acesso por*/}
            {/*        terceiro, e desde que*/}
            {/*        anonimizados os dados.</p><p>Assim, quando atingida a*/}
            {/*    finalidade do tratamento*/}
            {/*    dos dados pessoais, e eles não mais precisarem ser armazenados para satisfazer*/}
            {/*    quaisquer*/}
            {/*    exigências legais,*/}
            {/*    estes deverão, ressalvadas as hipóteses do parágrafo anterior, ser devidamente*/}
            {/*    eliminados física*/}
            {/*    e*/}
            {/*    digitalmente, com a comunicação desta eliminação ao titular nos casos em que ela se*/}
            {/*    dê de*/}
            {/*    maneira diversa*/}
            {/*    àquela prevista no termo de consentimento aplicável.</p><p*/}
            {/*>Portanto, o Clube de Tapejara firma o compromisso com a segurança e privacidade dos dados pessoais*/}
            {/*    coletados, mediante o*/}
            {/*    emprego de*/}
            {/*    medidas e soluções técnicas de proteção aptas a garantir a confidencialidade,*/}
            {/*    integridade e*/}
            {/*    inviolabilidade*/}
            {/*    dos dados, contando ainda, com medidas de segurança apropriadas aos riscos e com*/}
            {/*    controle de*/}
            {/*    acesso às*/}
            {/*    informações armazenadas.<br/><br/><br/></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Das medidas de segurança e dos critérios de armazenagem dos*/}
            {/*            dados.</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><br/>Para mantermos suas informações pessoais seguras, usamos*/}
            {/*        ferramentas*/}
            {/*        físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.*/}
            {/*        Aplicamos*/}
            {/*        essas*/}
            {/*        ferramentas levando em consideração a natureza dos dados pessoais coletados, o*/}
            {/*        contexto e a*/}
            {/*        finalidade*/}
            {/*        do tratamento e os riscos que eventuais violações gerariam para os direitos e*/}
            {/*        liberdades do*/}
            {/*        titular dos*/}
            {/*        dados coletados e tratados.</p><p>A SAS se*/}
            {/*    compromete a adotar*/}
            {/*    as melhores posturas para evitar incidentes de segurança baseando-se nas boas*/}
            {/*    práticas previstas*/}
            {/*    na ISO*/}
            {/*    27001/27002 e Lei 13.709/2018.</p><p>Eventuais cópias de dados*/}
            {/*    pessoais somente*/}
            {/*    devem ser feitas em caso de necessidade para cumprimento da finalidade proposta ao*/}
            {/*    tratamento, e*/}
            {/*    todas as*/}
            {/*    cópias feitas devem ser registradas, que deve ser guardada digitalmente com os*/}
            {/*    mesmos critérios*/}
            {/*    de*/}
            {/*    segurança.<br/><br/><br/></p>*/}
            {/*    <ol>*/}
            {/*        <li><strong>Prestação de informações e transparência e do encarregado de*/}
            {/*            proteção de dados –*/}
            {/*            DPO.</strong></li>*/}
            {/*    </ol>*/}
            {/*    <p><br/>A SAS se compromete a prover todas as*/}
            {/*        informações requeridas*/}
            {/*        pelos titulares acerca do tratamento de seus dados pessoais, respeitando o*/}
            {/*        direito da*/}
            {/*        empresa de manter*/}
            {/*        sigilo comercial quando cabível. A finalidade do tratamento deve ser sempre*/}
            {/*        evidenciada e*/}
            {/*        transparente.*/}
            {/*    </p><p>Quando houver solicitação da prestação de informações sobre*/}
            {/*    os dados*/}
            {/*    pessoais pelo titular destes, os operadores deverão informar o Encarregado da*/}
            {/*    Proteção de Dados*/}
            {/*    Pessoais da*/}
            {/*    solicitação e então prestar as informações solicitadas ao titular.</p><p*/}
            {/*>O*/}
            {/*    encarregado da proteção de dados pessoais será a pessoa responsável – nos termos da*/}
            {/*    LGPD – pela*/}
            {/*    comunicação*/}
            {/*    entre os titulares, a SAS e a Autoridade Nacional de Proteção de Dados*/}
            {/*    (ANDP). São*/}
            {/*    atribuições do*/}
            {/*    encarregado verificar os riscos existentes, apontar as medidas corretivas e avaliar*/}
            {/*    periodicamente a*/}
            {/*    segurança de dados pessoais dentro da empresa, devendo também realizar eventuais*/}
            {/*    comunicações*/}
            {/*    necessárias*/}
            {/*    com os titulares ou com o poder público. Quaisquer questionamentos que surgirem no*/}
            {/*    dia-a-dia da*/}
            {/*    empresa*/}
            {/*    acerca da proteção de dados pessoais devem ser levados ao Encarregado para que este*/}
            {/*    possa*/}
            {/*    orientar de*/}
            {/*    imediato o operador ou buscar junto à ANDP e demais entidades especializadas uma*/}
            {/*    orientação*/}
            {/*    adequada ao*/}
            {/*    questionamento levantado.</p><p>O Encarregado da Proteção de Dados*/}
            {/*    Pessoais*/}
            {/*    manterá relatório de avaliação de riscos e impactos à proteção de dados pessoais,*/}
            {/*    por meio do*/}
            {/*    qual as*/}
            {/*    medidas necessárias à segurança da informação de dados pessoais poderão ser*/}
            {/*    estruturadas,*/}
            {/*    implementadas e*/}
            {/*    avaliadas.</p>*/}
            </div>
        </>
    );
}

