import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import api from "../services/api";
import CryptoJS from 'crypto-js';
import {MainContext} from "./MainContext";

type Props = {
  children?: ReactNode;
}

type IAuthContext = {
  authenticated: boolean;
  setAuthenticated: (newState: boolean) => void
  authUser: any;
  setAuthUser: (newState: object) => void
  isAthlete: boolean;
  setIsAthlete: (newState: boolean) => void
  setAuthentication: (token: string) => void
  getRefreshToken: () => void
}

let initialValue = {
  authenticated: false,
  setAuthenticated: () => {},
  authUser: {},
  setAuthUser: () => {},
  isAthlete: true,
  setIsAthlete: () => {},
  setAuthentication: () => {},
  getRefreshToken: () => {}
}

const APP_AUTH_ENCRYPTION_KEY: any = process.env.REACT_APP_AUTH_ENCRYPTION_KEY
const APP_AUTH_SALT: any = process.env.REACT_APP_AUTH_SALT

const checkUserAuth = () => {
  let user: any
  const _user:any = localStorage.getItem("token")

  if (_user) {
    const decrypted = CryptoJS.AES.decrypt(_user, APP_AUTH_ENCRYPTION_KEY, { iv:  APP_AUTH_SALT }).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);

    api.defaults.headers.common['Authorization'] = `Bearer ${user.token.token}`;
  }

  initialValue.authenticated = !!user;
  initialValue.authUser = user?.user;
  initialValue.isAthlete = user?.user.role === 'athlete';
}

const AuthContext = createContext<IAuthContext>(initialValue)

const AuthProvider = ({children}: Props) => {
  const {getClub} = useContext(MainContext)
  checkUserAuth();

  const [ authenticated, setAuthenticated ] = useState(initialValue.authenticated)
  const [ authUser, setAuthUser ] = useState(initialValue.authUser)
  const [ isAthlete, setIsAthlete ] = useState(initialValue.isAthlete)

  useEffect(() => {
    if (authenticated){
      getClub()
    }
  },  [])

  async function getRefreshToken() {
    api.post('/api/refresh').then((result) => {
      setAuthentication(result.data)
    })
  }

  async function setAuthentication(token:any) {
    localStorage.setItem('token', token);
    let user: any

    const decrypted = CryptoJS.AES.decrypt(token, APP_AUTH_ENCRYPTION_KEY, { iv:  APP_AUTH_SALT }).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);

    api.defaults.headers.common['Authorization'] = `Bearer ${user.token.token}`;

    setAuthenticated(!!user)
    setAuthUser(user?.user)
    setIsAthlete(user?.user.role === 'athlete')

    getClub()
  }

  return (
    <AuthContext.Provider value={{authenticated, setAuthenticated, authUser, setAuthUser, setAuthentication, isAthlete, setIsAthlete, getRefreshToken}}>
      {children}
    </AuthContext.Provider>
  )
}

export {  AuthContext, AuthProvider }