import React, {useContext} from "react";
import Select from "react-select";
import {AthleteContext} from "../../context/Athele/AthleteContext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AuthContext} from "../../context/AuthContext";

export default function PresidentForm() {
    const {active, memberNumber, setMemberNumber,
        membershipDate, setMembershipDate,
        disaffiliationDate, setDisaffiliationDate,
        membershipType, setMembershipType,
        } = useContext(AthleteContext)

    const { authUser } = useContext(AuthContext)

    return (
        <>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                    <div className={active ?"col-md-4" : "col-md-3"}>
                        <label htmlFor="associationDate" className="form-label">Data de filiação <i
                            className="text-danger"> *</i></label>
                        <DatePicker  className={"form-control sadate"}
                                     disabled={authUser?.role === 'athlete'}
                                     value={membershipDate}
                                     onChange={evento => setMembershipDate(evento)}/>
                    </div>
                </LocalizationProvider>

                <div className={active ?"col-md-4" : "col-md-3"}>
                    <label htmlFor="quantity_shots" className="form-label">Número de sócio <i
                        className="text-danger"> *</i></label>
                    <input type="number" disabled={authUser?.role === 'athlete'} className="form-control" id="quantity_shots" placeholder="0"
                           onChange={evento => setMemberNumber(evento.target.value)} value={memberNumber}/>
                </div>

                <div className={active ?"col-md-4" : "col-md-3"}>
                    <label htmlFor="membership_type" className="form-label">Tipo filiação<i
                        className="text-danger"> *</i></label>
                    <Select
                        defaultValue={{label: 'Individual', value: 'I'}}
                        options={[{label: 'Individual', value: 'I'}, {label: 'Familiar', value: 'F'}]}
                        value={[{label: 'Individual', value: 'I'}, {
                            label: 'Familiar',
                            value: 'F'
                        }].find(option => option.value == membershipType)}
                        name='membership_type'
                        isDisabled={authUser?.role === 'athlete'}
                        id='membership_type'
                        onChange={(data: any) => {
                            setMembershipType(data.value)
                        }}
                    />
                </div>
            {(authUser?.role !== 'athlete' && !active) &&
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                    <div className="col-md-3">
                        <label htmlFor="disaffiliation_date" className="form-label">Data de desfiliação <i
                            className="text-danger"> *</i></label>
                            <DatePicker  className={"form-control sadate"}
                                         value={disaffiliationDate}
                                         onChange={evento => setDisaffiliationDate(evento)}/>
                    </div>
                </LocalizationProvider>
            }
        </>
    );
}

