import FormAthletes from "./form";
import {AddressProvider} from "../../context/Address/AddressContext";
import {AthleteProvider} from "../../context/Athele/AthleteContext";
import {PasswordProvider} from "../../context/PasswordContext";
import {useContext, useEffect} from "react";
import {MainContext} from "../../context/MainContext";
import {AuthContext} from "../../context/AuthContext";

export default function AthleteSubmissionForm() {
    const { authUser } = useContext(AuthContext)
    const {setPageTitle} = useContext(MainContext)

    useEffect(() => {
        if (authUser?.role === "athlete"){
            setPageTitle("Meu perfil")
        }else{
            setPageTitle("Cadastro de Sócios")
        }

    }, []);

  return (
      <>
        <AddressProvider>
            <PasswordProvider>
                <AthleteProvider>
                      <FormAthletes />
                </AthleteProvider>
            </PasswordProvider>
        </AddressProvider>
      </>
  )
}