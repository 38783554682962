import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import api from "../../services/api";
import {validaCPF} from "../../assets/js/helper";
import dayjs from "dayjs";
import {AuthContext} from "../AuthContext";

type Props = {
    children?: ReactNode;
}

const initialValue = {
    active: true,
    setActive: () => {},
    incompleteRegistration: 0,
    setIncompleteRegistration: () => {},
    fullName: "",
    setFullName: () => {},
    cpf: "",
    setCpf: () => {},
    rg: "",
    setRg: () => {},
    crNumber: "",
    setCrNumber: () => {},
    genre: "M",
    setGenre: () => {},
    level: "1",
    setLevel: () => {},
    phone: "",
    setPhone: () => {},
    birthDate: null,
    setBirthDate: () => {},
    crExpedition: null,
    setCrExpedition: () => {},
    crValidity: null,
    setCrValidity: () => {},
    phoneDispatcher: "",
    setPhoneDispatcher: () => {},
    emailDispatcher: "",
    setEmailDispatcher: () => {},
    arrayLevels: [],
    setArrayLevels: () => {},
    memberNumber: null,
    setMemberNumber: () => {},
    membershipDate: null,
    setMembershipDate: () => {},
    disaffiliationDate: null,
    setDisaffiliationDate: () => {},
    membershipType: "i",
    setMembershipType: () => {},
}

const AthleteContext = createContext<any>(initialValue)

const AthleteProvider = ({children}: Props) => {
    const [memberNumber, setMemberNumber] = useState(initialValue.memberNumber );
    const [membershipDate, setMembershipDate] = useState(initialValue.membershipDate);
    const [disaffiliationDate, setDisaffiliationDate] = useState(initialValue.disaffiliationDate);
    const [membershipType, setMembershipType] = useState(initialValue.membershipType as any);

    const [active, setActive] = useState(initialValue.active);
    const [incompleteRegistration, setIncompleteRegistration] = useState(initialValue.incompleteRegistration);
    const [fullName, setFullName] = useState(initialValue.fullName);
    const [cpf, setCpf] = useState(initialValue.cpf);
    const [rg, setRg] = useState(initialValue.rg);
    const [genre, setGenre] = useState(initialValue.genre as any);
    const [level, setLevel] = useState(initialValue.level as any);
    const [phone, setPhone] = useState(initialValue.phone);
    const [birthDate, setBirthDate] = useState(initialValue.birthDate);
    const [crNumber, setCrNumber] = useState(initialValue.crNumber);
    const [crExpedition, setCrExpedition] = useState(initialValue.crExpedition);
    const [crValidity, setCrValidity] = useState(initialValue.crValidity);
    const [phoneDispatcher, setPhoneDispatcher] = useState(initialValue.phoneDispatcher);
    const [emailDispatcher, setEmailDispatcher] = useState(initialValue.emailDispatcher);

    const [arrayLevels, setArrayLevels] = useState(initialValue.arrayLevels as any)

    const { authUser } = useContext(AuthContext)

    async function levels(value = '') {
        const response = await api.get(`/api/levels`)
        const result = await response
        return setArrayLevels(
            await result.data.map((leve:any) => ({
                label: leve.description ,
                value: leve.id
            })),
        )
    }

    const validateAthleteRegister = () => {
        if (!fullName) {
            throw "Nome completo deve ser informado";
        }

        if (!cpf) {
            throw "CPF deve ser informado";
        }

        if(!validaCPF(cpf)){
            throw "CPF inválido";
        }


        if (authUser?.role === 'athlete'){
            if (!rg) {
                throw "RG deve ser informado";
            }

            if (!phone) {
                throw "Celular deve ser informado";
            }

            if (!birthDate) {
                throw "Data de nascimento deve ser informada";
            }
        }
    }

        const dispatchAthleteObject = {
        // "address_id": addressId,

        "active": active,
        "incomplete_registration": incompleteRegistration,
        "full_name": fullName,
        "birth_date": birthDate != null ? dayjs(birthDate).format('YYYY-MM-DD') : null ,
        "cpf": cpf,
        "rg": rg,
        "genre": genre,
        "phone": phone,
        "membership_date": membershipDate != null ? dayjs(membershipDate).format('YYYY-MM-DD') : null ,
        "member_number": memberNumber,
        "membership_type": membershipType,
        "disaffiliation_date": disaffiliationDate != null ? dayjs(disaffiliationDate).format('YYYY-MM-DD') : null ,
        "cr_number": crNumber,
        "cr_expedition_date": crExpedition != null ? dayjs(crExpedition).format('YYYY-MM-DD') : null ,
        "cr_validity_date": crValidity != null ? dayjs(crValidity).format('YYYY-MM-DD') : null ,
        "phone_dispatcher": phoneDispatcher,
        "email_dispatcher": emailDispatcher,
        "level_id": level
    }

    useEffect(() => {
        levels()
    },  [])

    return (
        <>
            <AthleteContext.Provider value={{
                active, setActive,
                incompleteRegistration, setIncompleteRegistration,
                fullName, setFullName,
                cpf, setCpf,
                rg, setRg,
                genre, setGenre,
                phone, setPhone,
                birthDate, setBirthDate,
                level, setLevel,
                arrayLevels, setArrayLevels,
                crNumber, setCrNumber,
                crExpedition, setCrExpedition,
                crValidity, setCrValidity,
                phoneDispatcher, setPhoneDispatcher,
                emailDispatcher, setEmailDispatcher,
                memberNumber, setMemberNumber,
                membershipDate, setMembershipDate,
                disaffiliationDate, setDisaffiliationDate,
                membershipType, setMembershipType,
                dispatchAthleteObject,
                validateAthleteRegister
            }}>
                {children}
            </AthleteContext.Provider>

        </>
    )
}

export { AthleteContext, AthleteProvider }