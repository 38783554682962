import {createContext, ReactNode, useState} from 'react'

type Props = {
    children?: ReactNode;
}

let initialValue = {
    addressId: null,
    setAddress: () => {},
    zipCode: '',
    setZipCode: () => {},
    street: '',
    setStreet: () => {},
    number: '',
    setNumber: () => {},
    district: '',
    setDistrict: () => {},
    complement: '',
    setComplement: () => {},
    city: {
        value: null,
        label: 'Selecione uma cidade',
    },
    setCity: () => {}
}

const AddressContext = createContext<any>(initialValue)

const AddressProvider = ({children}: Props) => {

    const [addressId, setAddress] = useState(initialValue.addressId)
    const [zipCode, setZipCode] = useState(initialValue.zipCode);
    const [street, setStreet] = useState(initialValue.street);
    const [number, setNumber] = useState(initialValue.number);
    const [district, setDistrict] = useState(initialValue.district);
    const [complement, setComplement] = useState(initialValue.complement);
    const [city, setCity] = useState(initialValue.city as any);

    let dispatchAddressObject = {
        "id": addressId,
        "street": street,
        "number": number,
        "district": district,
        "zip_code": zipCode,
        "complement": complement,
        "city_id": city?.value,
    }

    const validateAddressRegister = () => {
        if (!zipCode) {
            throw "Cep deve ser informado";
        }

        if (!street) {
            throw "Rua deve ser informada";
        }

        if (!number) {
            throw "Número deve ser informado";
        }

        if (!district) {
            throw "Bairro deve ser informado";
        }

        if (!city.value) {
            throw "Cidade deve ser selecionada";
        }
    }

    return (
     <>
        <AddressContext.Provider value={{
            addressId, setAddress,
            zipCode, setZipCode,
            street, setStreet,
            number ,setNumber,
            district, setDistrict,
            complement, setComplement,
            city, setCity,
            dispatchAddressObject,
            validateAddressRegister
        }}>
            {children}
        </AddressContext.Provider>
     </>
    )
}

export { AddressContext, AddressProvider }