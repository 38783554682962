import { Link } from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {MainContext} from "../../context/MainContext";

export default function ListingUsers() {
    const {setPageTitle, setLoading} = useContext(MainContext)
    const [users, setUsers] = useState<any>([])

    const getUsers = async () => {
        await api.get('/api/users').then((response) => {
            setUsers(response.data)
        })
    }

    useEffect(() => {
        setPageTitle("Usuários")
        setLoading(true)

        const fetchData = async () => {
            await getUsers()
        };

        fetchData().then(setLoading(false))
    }, [])

    const columns: any = [
        {
            name: 'username', label: 'Nome', options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'email', label: 'E-mail', options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: '', label: '', options: {
                filter: false,
                false: true,
                customBodyRenderLite: (index: string | number) => {return (
                    <>
                        <Link className='btn btn-sm btn-warning' to={`/admin/users/update/${users[index]?.id}`} title="Editar"><i className="bi bi-pencil" aria-hidden="true"></i> </Link>
                    </>
                )
                },
            },
        },
    ]

    const options: any = DataTableOptions

    return (
        <>

            <div className='mb-4'>
                <Link className='btn btn-success mb-3' to='/admin/users/create'><i className="bi bi-plus-circle">&nbsp;</i> Cadastrar Usuario</Link>

                <MUIDataTable
                    title={''}
                    data={users}
                    columns={columns}
                    options={options}
                />
            </div>

        </>
    )
}