import React, {useContext, useEffect} from "react";
import InputMask from 'react-input-mask';
import Async from 'react-select/async';
import api from "../../services/api";
import axios from "axios";
import {AddressContext} from "../../context/Address/AddressContext";
import {SelectTheme} from "../../components/selectTheme";
import {AuthContext} from "../../context/AuthContext";

export default function AddressForm() {
    const {zipCode, setZipCode,
            street, setStreet,
            number ,setNumber,
            district, setDistrict,
            complement, setComplement,
            city, setCity} = useContext(AddressContext)

    const { authUser, authenticated } = useContext(AuthContext)

    useEffect(() => {
        cities()
    },  [])

    async function cities(value = '') {
        const response = await api.get(`/api/cities/?search=${value}`)
        const result = await response
        return await result.data.map((city:any) => ({
            label: city.name + ' - ' + city.state.abbreviation,
            value: city.id
        }))
    }

    async function addressSearch(zip: string = '') {
        zip = zip.replace('-', '')

        if (zip != '') {
            const response = await axios.get(`https://viacep.com.br/ws/${zip}/json/`)
            if (response.status == 200 && !response.data.erro!) {
                setStreet(response.data.logradouro);
                setNumber('');
                setDistrict(response.data.bairro);
                setComplement(response.data.complemento);

                const res = await api.get(`/api/searchCityIbge/?cod=${response.data.ibge}`)
                setCity({value: res.data.id, label: res.data.name})
            }
        }
    }

    return (
        <>
                <div className="col-md-4">
                    <label htmlFor="zipCode" className="form-label">Cep {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <InputMask type="text" className="form-control" id="zipCode" placeholder="Seu cep"
                               mask="99999-999" onChange={evento => setZipCode(evento.target.value)} value={zipCode}
                               onBlur={evento => addressSearch(evento.target.value)}
                    />
                </div>

                <div className="col-md-8">
                    <label htmlFor="street" className="form-label">Rua {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <input type="text" className="form-control" id="street" placeholder="Rua ..."
                           onChange={evento => setStreet(evento.target.value)} value={street}/>
                </div>

                <div className="col-md-4">
                    <label htmlFor="number" className="form-label">Numero {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <input type="text" className="form-control" id="number" placeholder="Numero"
                           onChange={evento => setNumber(evento.target.value)} value={number}/>
                </div>

                <div className="col-md-8">
                    <label htmlFor="district" className="form-label">Bairro {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <input type="text" className="form-control" id="district" placeholder="Seu bairro"
                           onChange={evento => setDistrict(evento.target.value)} value={district}/>
                </div>


                <div className="col-md-6">
                    <label htmlFor="complement" className="form-label">Complemento</label>
                    <input type="text" className="form-control" id="complement" placeholder="Ex: Ap 123"
                           onChange={evento => setComplement(evento.target.value)} value={complement}/>
                </div>

                <div className="col-md-6">
                    <label htmlFor="inputCity" className="form-label">Cidade {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <Async
                        name="search"
                        isLoading={false}
                        cacheOptions
                        loadOptions={cities}
                        noOptionsMessage={() => "Nenhum registro encontrado"}
                        placeholder='Sua cidade'
                        onChange={(data: any) => {
                            setCity(data);
                        }}
                        value={city}
                        theme={(theme) => (SelectTheme(theme))}
                        defaultOptions
                    />
                </div>
        </>
    );
}