import React, {createContext, ReactNode, useState} from 'react'
import api from "../services/api";

type Props = {
    children?: ReactNode;
}

let initialValue = {
    club: {},
    setClub: () => {},
    loading: false,
    setLoading: () => {},
    pageTitle: '',
    setPageTitle: () => {},
}

const MainContext = createContext<any>(initialValue)

const MainProvider = ({children}: Props) => {
    const [ club, setClub ] = useState(initialValue.club)
    const [ loading, setLoading ] = useState(initialValue.loading)
    const [ pageTitle, setPageTitle ] = useState(initialValue.pageTitle)

    async function getClub() {
        await api.get('/api/clubs/1').then((response) => {
            setClub(response.data)
        })
    }

    return (
        <>
            <MainContext.Provider value={{club, setClub, getClub, setLoading, pageTitle, setPageTitle}}>
                {children}
            </MainContext.Provider>

            {loading &&
                <div className="loading">
                    <span className="loader"></span>
                </div>
            }

        </>
    )
}

export {MainContext, MainProvider}