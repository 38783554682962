import React, {useContext, useEffect, useState} from "react";
import InputMask from 'react-input-mask';
import Select from "react-select";
import {AthleteContext} from "../../context/Athele/AthleteContext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export default function CacForm() {

    const { level, setLevel,
        arrayLevels,
        crNumber, setCrNumber,
        crExpedition, setCrExpedition,
        crValidity, setCrValidity,
        phoneDispatcher, setPhoneDispatcher,
        emailDispatcher, setEmailDispatcher} = useContext(AthleteContext)

    return (
        <>
                <div className="col-md-3">
                    <label htmlFor="level" className="form-label">Nível <i className="text-danger"> *</i></label>
                    <Select
                        options={arrayLevels}
                        name='level'
                        id='level'
                        onChange={(data: any) => {
                            setLevel(data.value)
                        }}
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                        value={arrayLevels.find((option: { value: any; }) => option.value == level) || ''}
                        placeholder='Seu nível'
                    />
                </div>

            <div className="col-md-3">
                <label htmlFor="cr_number" className="form-label">Nº CR <i style={{fontSize:"small"}}>(Incluindo zeros no início)</i> </label>
                <input type="text" className="form-control" id="cr_number" placeholder="Seu CR"
                       onChange={evento => setCrNumber(evento.target.value)} value={crNumber}/>
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">

                <div className="col-md-3">
                <label htmlFor="cr_expedition" className="form-label">Data expedição do CR </label>
                        <DatePicker  className={"form-control sadate"}
                                     value={crExpedition}
                                     onChange={evento => setCrExpedition(evento)}/>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="cr_validity" className="form-label">Data validade do CR </label>
                        <DatePicker  className={"form-control sadate"}
                                     value={crValidity}
                                     onChange={evento => setCrValidity(evento)}/>
                    </div>
                </LocalizationProvider>


            {crNumber.length > 0 &&
            <>
                <h3>Despachante</h3>

                <div className="col-md-4">
                    <label htmlFor="phoneDispatcher" className="form-label">Celular do despachante</label>
                    <InputMask type="text" className="form-control" id="phoneDispatcher"
                               placeholder="Seu número de celular"
                               mask="(99) 99999-9999" onChange={evento => setPhoneDispatcher(evento.target.value)}
                               value={phoneDispatcher}/>
                </div>

                <div className="col-md-6">
                    <label htmlFor="emailDispatcher" className="form-label">E-mail do despachante</label>
                    <input type="email" className="form-control" id="emailDispatcher" placeholder="Seu email"
                           onChange={evento => setEmailDispatcher(evento.target.value)} value={emailDispatcher}/>
                </div>
            </>
            }

        </>
    );
}

