import React, {useContext, useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../services/api'
import Swal from 'sweetalert2'
import PasswordForm from "../form";
import {PasswordContext} from "../../../context/PasswordContext";

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const { token } = useParams()

  const { password, validatePasswordRegister, passwordIdent} = useContext(PasswordContext)
  const [passwordUpdated, setPasswordUpdated] = useState('')

  const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault()

    validatePasswordRegister()

    if (passwordIdent) {
      api.post('/api/reset-password', {
        password: password,
        token: token,
      }).then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          text: result.data.message,
          icon: 'success',
          timer: 5000,
        }).then((result) => {
            return navigate("/login");
        })
      }).catch(erro => {
          Swal.fire({
            title: 'Erro!',
            text: erro.response.data.message,
            icon: 'error',
          })
        },
      )
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Senhas devem ser iguais!',
        icon: 'error',
      })
    }
  }

  return (
    <>
      {!passwordUpdated ? (
          <form onSubmit={forgot} autoComplete='off' className="row g-3">

            <h5 className={'text-center'}>Preencha os campos abaixo com a nova senha, para finalizar sua troca de senha.</h5>

            {/*    <label htmlFor='email'>Infome nova senha</label>*/}
            <PasswordForm/>

            <button type='submit' className='btn btn-primary btn-block mb-4'>
              Continuar
            </button>
          </form>
        ) :
        (
          <>
            <div className="row g-3">
              <h4>E-mail confirmado com sucesso, para finalizar a troca da sua senha clique no link enviado para o seu e-mail.</h4>
            </div>
          </>
        )


      }
    </>
  )

};