import { Link } from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {MainContext} from "../../context/MainContext";
import ISubEventTypes from "../../interfaces/ISubEventTypes";

export default function ListingSubEventType({ eventTypeId }:any) {
  const {setLoading} = useContext(MainContext)
  const [eventTypes, setEventTypes] = useState([] as any)

  const getEventTypes = async () => {
    try {
      await api.get<ISubEventTypes>(`/api/subEventTypes/eventTypes/${eventTypeId}`).then((response) => {
        setEventTypes(response.data)
      })
    } catch (error) {
      console.error('Error fetching event types:', error);
    }
  }

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      await getEventTypes()
    };

    fetchData().then(setLoading(false))
  }, [])

  const columns: any = [
    {
      name: 'description', label: 'Descrição', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: (index: string | number) => {return (
            <>
              <Link className='btn btn-sm btn-warning' to={`/events/sub/update/${eventTypes[index]?.id}`} title="Editar"><i className="bi bi-pencil" aria-hidden="true"></i> </Link>
            </>
          )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
    <>

      <div className='mb-4'>
        <Link className='btn btn-success mb-3' to={`/events/sub/create/${eventTypeId}`}><i className="bi bi-plus-circle">&nbsp;</i> Cadastrar Sub-Evento</Link>

        <MUIDataTable
          title={''}
          data={eventTypes}
          columns={columns}
          options={options}
        />
      </div>

    </>
  )
}