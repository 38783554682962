import React, {useEffect, useState} from "react";
import api from "../../../services/api";

export default function MembershipReport({ filtro }:any) {
    const [athletes, setAthletes] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)

    async function getAthletes() {
        console.log(filtro)
        await api.post(`/api/athletes/report`, {filtro}).then((response) => {
            setAthletes(response.data)
        }).then(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await getAthletes()
        };

        fetchData()
    }, [])

    return (
        <>
            {!loading &&
                <div className={'printTable'}>
                    <p style={{textAlign: 'center'}}>&nbsp;</p>
                    <p style={{textAlign: 'center'}}><span><strong>Relatório de Sócios</strong></span>
                    </p>

                    <table style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%'
                    }} className={'table'}>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'center'}} width="80"> Nº</td>
                            <td style={{textAlign: 'center'}} width="150"> Data</td>
                            <td>Nome</td>
                            <td>Situação</td>
                            <td width="200"> CPF</td>
                        </tr>

                        {athletes.map((athlete: any, index: number) =>
                            <tr key={`${index}`} className={`${athlete?.active ? '' : 'table-danger'}`}>
                                <td>{athlete?.member_number}</td>
                                <td style={{textAlign: 'center'}}>{athlete?.membership_date ? new Date(athlete?.membership_date).toLocaleString('pt-BR').substr(0, 10) : ''}</td>
                                <td>{athlete?.full_name}</td>
                                <td>
                                    <span
                                        className={`badge rounded-pill ${athlete?.active ? 'bg-success' : 'bg-danger'}`}>{athlete?.active ? 'Ativo' : 'Desassociou'}</span>
                                </td>
                                <td>{athlete?.cpf}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}