import React, {useContext, useEffect, useState} from "react";
import PrintingComponent from "../../components/printingComponent";
import MembershipReport from "./Report/MembershipReport";
import Select from "react-select";
import {MainContext} from "../../context/MainContext";

export default function AthetesReport({ athleteId }:any) {
    const {setPageTitle} = useContext(MainContext)

    const [print, setPrint] = useState<any>({})
    const [status, setStatus] = useState<any>('')

    const childToParent = (childdata:any) => {
        setPrint({imprimir:false})
    }

    useEffect(() => {
        setPageTitle("Relatório de Sócios")
    }, [])

    return (
        <>
            <div>

                <div className="col-md-4">
                    <label htmlFor="status" className="form-label">Status</label>
                    <Select
                        defaultValue={{label: 'Todos', value: ''}}
                        options={[{label: 'Todos', value: ''}, {label: 'Ativos', value: '1'}, {label: 'Inativos', value: '0'}]}
                        value={[{label: 'Todos', value: ''}, {label: 'Ativos', value: '1'}, {label: 'Inativos', value: '0'}
                        ].find(option => option.value == status)}
                        name='status'
                        id='status'
                        onChange={(data: any) => {
                            setStatus(data.value)
                        }}
                    />
                </div>

                <button type={"button"} className="btn btn-primary mt-2"
                        onClick={() => setPrint({
                            imprimir: true
                        })}>
                    <i className="bi bi-printer-fill"></i> Imprimir
                </button>
            </div>
            {print.imprimir &&
                <PrintingComponent componentToPrint={<MembershipReport filtro={{status: status}}/>}
                                   textButon={""} childToParent={childToParent}/>
            }
        </>
    )
}