import React, {useContext, useEffect, useState} from "react";
import api from "../../services/api";
import {dateMountString} from "../../assets/js/helper";
import {MainContext} from "../../context/MainContext";

export default function CommitmentParticipation({ athleteId }:any) {
    const [athlete, setAthlete] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)


    const {club} = useContext(MainContext)

    async function getAthlete() {
        await api.get(`/api/athletes/${athleteId}`).then((response) => {
            setAthlete(response.data)
        }).then(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await getAthlete()
        };

        fetchData()
    }, [club])

    return (
        <>
            {!loading &&
                <div className={'printTable'}>
                <p style={{textAlign: 'center'}}>&nbsp;<strong style={{fontSize: 'large'}}>ANEXO C</strong></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}><strong>DECLARAÇÃO DE COMPROMISSO DE PARTICIPAÇÃO EM TREINAMENTOS E COMPETIÇÕES</strong></span>
                </p>
                <p style={{textAlign: 'center'}}><span
                    style={{fontSize: 'large'}}>(art. 35 do Decreto nº 11.615/2023)</span>
                </p>
                <p style={{textAlign: 'center'}}>&nbsp;</p>
                <p><span style={{fontSize: 'large'}}>Dados da Entidade de Tiro de Vinculação</span></p>
                <table style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%'
                }}>
                    <tbody>
                    <tr>
                        <td width="270"> NOME
                        </td>
                        <td style={{textAlign: 'center'}}> {club?.fantasia}
                        </td>
                        <td width="400"> CNPJ: {club?.cnpj}
                        </td>
                    </tr>
                    <tr>
                        <td> CERTIFICADO DE REGISTRO
                        </td>
                        <td style={{textAlign: 'center'}}>
                             {club?.cr_number}
                        </td>
                        <td> DATA VAL: {new Date(club?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10)}
                        </td>
                    </tr>
                    <tr>
                        <td> ENDEREÇO
                        </td>
                        <td> {club?.address?.street}, {club?.address?.number}, {club?.address?.district}
                        </td>
                        <td>{club?.address?.city?.name} - {club?.address?.city?.state?.abbreviation}
                        </td>
                    </tr>
                    <tr>
                        <td> DADOS DA FILIAÇÃO
                        </td>
                        <td>NÚMERO: {athlete?.member_number}
                        </td>
                        <td>DATA: {athlete?.membership_date ? new Date(athlete?.membership_date).toLocaleString('pt-BR').substr(0, 10) : ''}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p><br/></p>
                <p><span style={{fontSize: 'large'}}><strong>Compromisso </strong></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: 'large'}}>Eu, {athlete?.full_name}, CPF:{athlete?.cpf} , IDENTIDADE: {athlete?.rg} SJS/RS, domiciliado em {athlete?.address?.street}, {athlete?.address?.number}, {athlete?.address?.district}, na cidade de {athlete?.address?.city?.name} – {athlete?.address?.city?.state?.abbreviation}, telefone: {athlete?.phone} filiado à Entidade de Tiro acima nomeada, ME COMPROMETO a comprovar, no mínimo, por calibre registrado, oito treinamentos ou competições em clube de tiro, em eventos distintos, a cada doze meses, em cumprimento ao previsto no art. 35 do Decreto nº 11.615/2023, como requisitos de assiduidade em prática de tiro desportivo junto ao Exército Brasileiro. </span>
                </p>
                <p style={{textAlign: 'justify'}}><span
                    style={{fontSize: 'large'}}>TAPEJARA, {dateMountString(new Date())}.</span></p>
                <p style={{textAlign: 'justify'}}><br/><br/></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>_________________________</span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>{athlete?.full_name}</span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>CPF: {athlete?.cpf}</span></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>_________________________</span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>{club?.president_name}</span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>CPF: {club?.president_cpf}</span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: 'large'}}>Presidente do Clube de Tiro Caça e Pesca Tapejara – RS</span>
                </p>
            </div>
            }
        </>
    )
}