import ClubForm from "./form";
import {AddressProvider} from "../../context/Address/AddressContext";

export default function ClubSubmissionForm() {

  return (
      <>
        <AddressProvider>
            <ClubForm/>
        </AddressProvider>
      </>
  )
}