import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../../services/api'
import Swal from 'sweetalert2'

export default function ForgotPasswordForm() {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [emailEnviado, setEmailEnviado] = useState<boolean>(false)

  const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault()
    api.post('/api/forgot-password', {
      email: email,
      resetPasswordUrl: `${process.env.REACT_APP_API_HOST}/resetPassword`,
    }).then((result) => {
      Swal.fire({
        title: 'Sucesso!',
        text: result.data.message,
        icon: 'success',
        timer: 5000,
      }).then((result) => {
        setEmailEnviado(true)
      })
    }).catch(erro => {
        Swal.fire({
          title: 'Erro!',
          text: erro.response.data.message,
          icon: 'error',
        })
      },
    )
  }

  return (

    <>
      {!emailEnviado ? (
              <form onSubmit={forgot} autoComplete='off' className="row g-3">
                <h5>Se você esqueceu sua senha, insira o seu email enviaremos um link para você voltar a acessar a sua conta.</h5>

                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">Infome seu e-mail cadastrado </label>
                  <input type="email" className="form-control" id="email" placeholder="E-mail cadastrado"
                         onChange={evento => setEmail(evento.target.value)} value={email}/>
                </div>

                <button type='submit' className='btn btn-primary btn-block mb-4'>
                  Continuar
                </button>
              </form>
          ) :
          (
              <>
                <div className="row g-3">
                <h4>E-mail confirmado com sucesso, para finalizar a troca da sua senha clique no link enviado para o seu
                  e-mail.</h4>
                </div>
              </>
          )
      }
    </>
  )
}