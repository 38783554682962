import Navbar from "./navbar";
import Sidebar from "./sidebar";
import React, {useContext, useEffect} from "react";
import {Link, Outlet} from "react-router-dom";
import '../../assets/js/main'
import {MainContext} from "../../context/MainContext";

export default function Content() {
    const {pageTitle} = useContext(MainContext)

    useEffect(() => {
        // Easy selector helper function
        const select = (el: string, all = false) => {
            return all ? document.querySelectorAll(el) : document.querySelector(el) as HTMLElement | null;
        };

        // Easy event listener function
        const on = (type: string, el: string, listener: EventListenerOrEventListenerObject, all = false) => {
            const elements = all ? document.querySelectorAll(el) : document.querySelector(el) as HTMLElement | null;
            if (elements instanceof NodeList) {
                elements.forEach((element) => element.addEventListener(type, listener));
            } else if (elements instanceof Node) {
                elements.addEventListener(type, listener);
            }
        };

        // Easy on scroll event listener
        const onscroll = (el: HTMLElement, listener: EventListenerOrEventListenerObject) => {
            el.addEventListener('scroll', listener);
        };

        // Sidebar toggle
        const toggleSidebar = () => {
            const body = select('body') as HTMLElement;
            if (body) {
                body.classList.toggle('toggle-sidebar');
            }
        };

        const toggleSidebarBtn = select('.toggle-sidebar-btn') as HTMLElement;
        if (toggleSidebarBtn) {
            on('click', '.toggle-sidebar-btn', toggleSidebar);
        }

        // Navbar links active state on scroll
        const navbarlinksActive = () => {
            const position = window.scrollY + 200;
            const navbarlinks = document.querySelectorAll('#navbar .scrollto');
            navbarlinks.forEach((navbarlink) => {
                const sectionId = navbarlink.getAttribute('href');
                if (!sectionId) return;
                const section = select(sectionId) as HTMLElement;
                if (!section) return;
                if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
                    navbarlink.classList.add('active');
                } else {
                    navbarlink.classList.remove('active');
                }
            });
        };
        window.addEventListener('load', navbarlinksActive);
        onscroll(document.documentElement, navbarlinksActive);

        // Toggle .header-scrolled class to #header when page is scrolled
        const header = select('#header') as HTMLElement;
        if (header) {
            const headerScrolled = () => {
                if (window.scrollY > 100) {
                    header.classList.add('header-scrolled');
                } else {
                    header.classList.remove('header-scrolled');
                }
            };
            window.addEventListener('load', headerScrolled);
            onscroll(document.documentElement, headerScrolled);
        }

        // Back to top button
        const backToTop = select('.back-to-top') as HTMLElement;
        if (backToTop) {
            const toggleBackToTop = () => {
                if (window.scrollY > 100) {
                    backToTop.classList.add('active');
                } else {
                    backToTop.classList.remove('active');
                }
            };
            window.addEventListener('load', toggleBackToTop);
            onscroll(document.documentElement, toggleBackToTop);
        }

        // Initiate Bootstrap validation check
        const forms = document.querySelectorAll('.needs-validation');
        forms.forEach((form) => {
            form.addEventListener('submit', (event) => {
                if (!(form instanceof HTMLFormElement) || !form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            });
        });
    }, []);

    return (
      <>
              <header id="header" className="header fixed-top d-flex align-items-center">

                  <div className="d-flex align-items-center justify-content-between">
                      <Link to={"/"} className="logo d-flex align-items-center">
                          <img src="/assests/img/apple-touch-icon.png" alt=""/>
                          <span className="d-none d-lg-block">Clube Tapejara</span>
                      </Link>
                      <i className="bi bi-list toggle-sidebar-btn"></i>
                  </div>

                  <Navbar/>

              </header>

              <Sidebar/>


              <main id="main" className="main">

                  <div className="pagetitle mb-3">
                      <h1>{pageTitle}</h1>
                      {/*<nav>*/}
                      {/*    <ol className="breadcrumb">*/}
                      {/*        <li className="breadcrumb-item"><a href="/home">Home</a></li>*/}
                      {/*        <li className="breadcrumb-item active">Dashboard</li>*/}
                      {/*    </ol>*/}
                      {/*</nav>*/}
                  </div>


                  <section className="section">
                      <div className="row">

                          <Outlet />

                      </div>
                  </section>

              </main>

              <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
      </>
    );
}