import { Link } from 'react-router-dom'
import ResetPasswordForm from './resetPasswordForm'
import React from "react";
import {PasswordProvider} from "../../../context/PasswordContext";

export default function Reset() {

  return (
      <>
        <main>
          <div className="container">

            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12 d-flex flex-column align-items-center justify-content-center">

                    <div className="d-flex justify-content-center py-4">
                        <span className="logo-lg d-flex align-items-center w-auto">
                          <img src="/assests/img/apple-touch-icon.png" alt=""/>
                        </span>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">Recuperar senha</h5>
                        </div>

                        <PasswordProvider>
                          <ResetPasswordForm />
                        </PasswordProvider>

                        <div className="row g-3">
                          <Link className='btn btn-outline-success btn-block' to='/login'>Fazer login</Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </section>

          </div>
        </main>
      </>
  )

}